import React, { useEffect } from 'react';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import settings from '../../config/settings';
import Logger from '../Logger/Logger';
import { getCookieInformationLangauges } from '../../api/localization';

const mapStateToProps = (state: AppState) => ({
	profile: state.profile,
	currentCulture: state.cultures.currentCulture,
});

// This function will select the cookie user settings from Cookieinformation
const functionalCookiesCategory = 'cookie_cat_functional';
const marketingCookiesCategory = 'cookie_cat_marketing';
const statisticCookiesCategory = 'cookie_cat_statistic';

export const getCookieCategoriesAcceptance = () => {
	const cookieInformation = (window as any).CookieInformation;
	if (!cookieInformation || !cookieInformation.getConsentGivenFor) return;

	return {
		functional: cookieInformation.getConsentGivenFor(functionalCookiesCategory),
		marketing: cookieInformation.getConsentGivenFor(marketingCookiesCategory),
		statistic: cookieInformation.getConsentGivenFor(statisticCookiesCategory),
	};
};

const CookieInformation: React.FC<ReturnType<
	typeof mapStateToProps
>> = props => {

	useEffect(() => {

        // List of CookieInformation supported languages found here
        // https://support.cookieinformation.com/en/articles/4418538-languages-of-the-privacy-widgets
        const getCookieInformationSupportedLanguages = async () => {
            const supportedLanguages = await getCookieInformationLangauges();
						
			if (!settings.cookieinformation.id || !settings.cookieinformation.url) {
				const logger = new Logger();
				logger.warn("Cookie information ID or Url haven't been set");
			} else {
				const { profile } = props;
				let preferredLanguage = props.currentCulture || (profile && profile.language_id) || 'en';
									
				if (supportedLanguages[preferredLanguage] !== undefined) {
					preferredLanguage = supportedLanguages[preferredLanguage];
				}

				const scriptTag = document.createElement('script');
				scriptTag.setAttribute('src', settings.cookieinformation.url);
				scriptTag.setAttribute('id', 'CookieConsent');
				scriptTag.setAttribute('type', 'text/javascript');

				// Add a generated nonce to the script include for Cookieinformation to use when adding inline scripts to our page     
				const cookieNonce = document.getElementById('cookieNonce') as HTMLMetaElement;           
                cookieNonce && scriptTag.setAttribute('nonce', cookieNonce.content);

				// Cookie Information specific
				scriptTag.setAttribute('data-cbid', settings.cookieinformation.id);
				scriptTag.setAttribute('data-culture', preferredLanguage);
				scriptTag.setAttribute('data-gcm-enabled', 'false');

				// Append it
				document.head.appendChild(scriptTag);
			}
		}
      
       getCookieInformationSupportedLanguages();
    
    }, []);

	return null;
};

export default connect(mapStateToProps)(CookieInformation);
