import { LocationActionTypes, LocationAppStateModel } from './types';
import { createReducer } from 'typesafe-actions';
import { LocationActionType } from './actions';
import * as immutable from 'object-path-immutable';

const reducer = createReducer<LocationAppStateModel, LocationActionType>({
	countriesTranslations: {},
}, {
	[LocationActionTypes.SET_COUNTRIES_NEEDED]: (state, action) => {
		return { ...state, countriesNeeded: action.payload };
	},
	[LocationActionTypes.SET_STATES_NEEDED]: (state, action) => {
		return { ...state, statesNeeded: action.payload };
	},
	[
		LocationActionTypes.SET_CALLING_CODES_NEEDED
	]:
		(state, action) => {
			return { ...state, callingCodesNeeded: action.payload };
		}
	,
	[LocationActionTypes.SET_TIMEZONES_NEEDED]: (state, action) => {
		return { ...state, timezonesNeeded: action.payload };
	},
	[LocationActionTypes.GET_COUNTRIES_SUCCEED]: (state, action) => {
		return { ...state, countries: action.payload };
	},
	[
		LocationActionTypes.GET_COUNTRIES_TRANSLATIONS_SUCCEED]:
		(state, action) => {
			const countriesTranslations = immutable.set(
				state.countriesTranslations,
				`${action.payload.languageCode}`,
				action.payload.translations
			);

			return { ...state, countriesTranslations };
		},	
	[LocationActionTypes.GET_STATES_SUCCEED]: (state, action) => {
		const states = immutable.set(
			state.states,
			`${action.payload.countryCode}`,
			action.payload.countryStates
		);

		return { ...state, states };
	},
	[LocationActionTypes.GET_CALLING_CODES_SUCCEED]: (state, action) => {
			const callingCodes = immutable.set(
				state.callingCodes,
				`${action.payload.languageCode}`,
				action.payload.callingCodes
			);
	
			return { ...state, callingCodes };
		},
	[LocationActionTypes.GET_TIMEZONES_SUCCEED]: (state, action) => {
		return { ...state, timezones: action.payload };
	}
});

export { reducer as locationReducer };
