import * as React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import ProtectedRoute from './containers/ProtectedRoute/ProtectedRoute';
import Main from './components/Main/Main';

const Routes: React.FC = () => (
	<ReactRoutes>
		<Route path="/"
			element={
			<ProtectedRoute
				optionalAuthentication={true}>
				<Main/>	
			</ProtectedRoute>
		} />
	</ReactRoutes>
);

export default Routes;
