import { takeLatest, all, put, fork, select } from 'redux-saga/effects';
import {
	SET_LANGUAGES_NEEDED,
	SetLanguages,
	SetDepartments,
	SET_DEPARTMENTS_NEEDED,
	SetCompanyTypes,
	SetAreasOfInterest,
	SetAreaOfInterest,
	SET_AREAS_OF_INTEREST_NEEDED,
	SET_AREA_OF_INTEREST_NEEDED,
	SET_COMPANY_TYPES_NEEDED,
} from './actions';

import {
	Language,
	ReferencesActionTypes,
	ReferencesAppStateModel,
} from './types';
import { AppState } from '../';
import {
	ReferenceApiModel,
	getDepartments,
	getLanguages,
	getCompanyTypes,
	getAreasOfInterest
} from '../../api/referencesApi';
import { FieldUi } from '../../components/Fields/FormFields/FieldUi';

function* loadLanguages() {
	const { references, fieldsObject } = (yield select((state: AppState) => {
		return { references: state.references, fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { references: ReferencesAppStateModel; fieldsObject: any };

	if (
		!references.languagesNeeded ||
		(references.languages && references.languages.length)
	) {
		return;
	}

	const fields: FieldUi[] = yield select(
		(state: AppState) => state.progressiveFields.fields
	);

	let languagesApiUrl = '';

	if (fields?.length) {
		const language = fields.filter(item => {
			return item.name === fieldsObject.language.name;
		});
		if (language && language.length && language[0].api_url) {
			languagesApiUrl = language[0].api_url;
		}
	}
	const currentCulture: string = yield select(
		(state: AppState) => state.cultures.currentCulture
	);

	const languagesApi: ReferenceApiModel<Language> = yield getLanguages(
		languagesApiUrl,
		currentCulture
	);
	yield put(SetLanguages(languagesApi.items || []));
}

function* loadDepartments() {
	const { references, fieldsObject } = (yield select((state: AppState) => {
		return { references: state.references, fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { references: ReferencesAppStateModel; fieldsObject: any };

	if (
		!references.departmentsNeeded ||
		(references.departments && references.departments.length)
	) {
		return;
	}

	const fields: FieldUi[] = yield select(
		(state: AppState) => state.progressiveFields.fields
	);

	let departmentsApiUrl = '';

	if (fields?.length) {
		const department = fields.filter(item => {
			return item.name === fieldsObject.department.name;
		});
		if (department && department.length && department[0].api_url) {
			departmentsApiUrl = department[0].api_url;
		}
	}
	const currentCulture: string = yield select(
		(state: AppState) => state.cultures.currentCulture
	);

	const departmentsApi: ReferenceApiModel = yield getDepartments(
		departmentsApiUrl,
		currentCulture
	);
	yield put(SetDepartments(departmentsApi.items || []));
}

function* loadCompanyTypes() {
	const { references, fieldsObject } = (yield select((state: AppState) => {
		return { references: state.references, fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { references: ReferencesAppStateModel; fieldsObject: any };

	if (
		!references.companyTypesNeeded ||
		(references.companyTypes && references.companyTypes.length)
	) {
		return;
	}

	const fields: FieldUi[] = yield select(
		(state: AppState) => state.progressiveFields.fields
	);

	let companyTypesApiUrl = '';

	if (fields?.length) {
		const companyType = fields.filter(item => {
			return item.name === fieldsObject.company_type.name;
		});
		if (companyType && companyType.length && companyType[0].api_url) {
			companyTypesApiUrl = companyType[0].api_url;
		}
	}

	const currentCulture: string = yield select(
		(state: AppState) => state.cultures.currentCulture
	);

	const companyTypesApi: ReferenceApiModel = yield getCompanyTypes(
		companyTypesApiUrl,
		currentCulture
	);
	yield put(SetCompanyTypes(companyTypesApi.items || []));
}

function* loadAreasOfInterest() {
	const { references, fieldsObject } = (yield select((state: AppState) => {
		return { references: state.references, fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { references: ReferencesAppStateModel; fieldsObject: any };

	if (
		!references.areasOfInterestNeeded ||
		(references.areasOfInterest && references.areasOfInterest.length)
	) {
		return;
	}

	const fields: FieldUi[] = yield select(
		(state: AppState) => state.progressiveFields.fields
	);

	let areasOfInterestApiUrl = '';

	if (fields?.length) {
		const areaOfInterest = fields.filter(item => {
			return item.name === fieldsObject.areas_of_interest.name;
		});
		if (areaOfInterest?.length && areaOfInterest[0].api_url) {
			areasOfInterestApiUrl = areaOfInterest[0].api_url;
		}
	}

	const currentCulture: string = yield select(
		(state: AppState) => state.cultures.currentCulture
	);

	const areasOfInterestApi: ReferenceApiModel = yield getAreasOfInterest(
		areasOfInterestApiUrl,
		currentCulture
	);
	yield put(SetAreasOfInterest(areasOfInterestApi.items || []));
}

function* loadAreaOfInterest() {
	const { references, fieldsObject } = (yield select((state: AppState) => {
		return { references: state.references, fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { references: ReferencesAppStateModel; fieldsObject: any };

	if (
		!references.areaOfInterestNeeded ||
		(references.areaOfInterest?.length)
	) {
		return;
	}

	const fields: FieldUi[] = yield select(
		(state: AppState) => state.progressiveFields.fields
	);

	let areaOfInterestApiUrl = '';

	if (fields?.length) {
		const areaOfInterest = fields.filter(item => {
			return item.name === fieldsObject.area_of_interest.name;
		});
		if (areaOfInterest?.length && areaOfInterest[0].api_url) {
			areaOfInterestApiUrl = areaOfInterest[0].api_url;
		}
	}

	const currentCulture: string = yield select(
		(state: AppState) => state.cultures.currentCulture
	);

	const areaOfInterestApi: ReferenceApiModel = yield getAreasOfInterest(
		areaOfInterestApiUrl,
		currentCulture
	);
	yield put(SetAreaOfInterest(areaOfInterestApi.items || []));
}

function* watchLoadLanguages() {
	yield takeLatest(ReferencesActionTypes.GET_LANGUAGES, loadLanguages);
	yield takeLatest(ReferencesActionTypes.SET_LANGUAGES_NEEDED, function*(
		action: SET_LANGUAGES_NEEDED
	) {
		action.payload && (yield loadLanguages());
	});
}

function* watchLoadDepartments() {
	yield takeLatest(ReferencesActionTypes.GET_DEPARTMENTS, loadDepartments);
	yield takeLatest(ReferencesActionTypes.SET_DEPARTMENTS_NEEDED, function*(
		action: SET_DEPARTMENTS_NEEDED
	) {
		action.payload && (yield loadDepartments());
	});
}

function* watchLoadAreasOfInterest() {
	yield takeLatest(
		ReferencesActionTypes.GET_AREAS_OF_INTEREST,
		loadAreasOfInterest
	);
	yield takeLatest(
		ReferencesActionTypes.SET_AREAS_OF_INTEREST_NEEDED,
		function*(action: SET_AREAS_OF_INTEREST_NEEDED) {
			action.payload && (yield loadAreasOfInterest());
		}
	);
}

function* watchLoadAreaOfInterest() {
	yield takeLatest(
		ReferencesActionTypes.GET_AREA_OF_INTEREST,
		loadAreaOfInterest
	);
	yield takeLatest(
		ReferencesActionTypes.SET_AREA_OF_INTEREST_NEEDED,
		function*(action: SET_AREA_OF_INTEREST_NEEDED) {
			action.payload && (yield loadAreaOfInterest());
		}
	);
}

function* watchLoadCompanyTypes() {
	yield takeLatest(ReferencesActionTypes.GET_COMPANY_TYPES, loadCompanyTypes);
	yield takeLatest(ReferencesActionTypes.SET_COMPANY_TYPES_NEEDED, function*(
		action: SET_COMPANY_TYPES_NEEDED
	) {
		action.payload && (yield loadCompanyTypes());
	});
}

export default function* culturesSagas() {
	yield all([
		fork(watchLoadLanguages),
		fork(watchLoadDepartments),
		fork(watchLoadAreasOfInterest),
		fork(watchLoadAreaOfInterest),
		fork(watchLoadCompanyTypes),
	]);
}
