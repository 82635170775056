export interface Token {
  accessToken: string;
  aud: string[],
  clientId: string,
  exp: number,
  iat: number,
  iss: string,
  sub: string,
  skip_accept_of_application_terms?: boolean,
  skip_accept_of_dip_terms_and_privacy?: boolean,
  skip_accept_of_pipl_terms?: boolean
}

export enum TokenActionTypes {
    SET_ACCESS_TOKEN = '@@token/SET_ACCESS_TOKEN',
    CLEAR_TOKEN = '@@token/CLEAR_TOKENS',
    PROCESS_TOKEN = '@@token/PROCESS_TOKEN',
    PROCESS_TOKEN_SUCCEEDED = '@@token/PROCESS_TOKEN_SUCCEEDED',
    PROCESS_TOKEN_FAILED = '@@token/PROCESS_TOKEN_FAILED',
}
