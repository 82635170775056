import { createAsyncAction, action } from 'typesafe-actions'
import { LegalInfoActionTypes } from './types';
import { DipLegalInfo } from '../../api/applicationDataApi';

export interface FetchLegalInfo {
	type: LegalInfoActionTypes.FETCH_LEGAL_INFO;
}

export interface FetchLegalInfoSucceed {
	type: LegalInfoActionTypes.FETCH_LEGAL_INFO_SUCCEED;
	payload: DipLegalInfo;
}

export interface FetchLegalInfoFailed {
	type: LegalInfoActionTypes.FETCH_LEGAL_INFO_FAILED;
	payload: Error;
}

export const FetchLegalInfo = createAsyncAction(
	LegalInfoActionTypes.FETCH_LEGAL_INFO,
	LegalInfoActionTypes.FETCH_LEGAL_INFO_SUCCEED,
	LegalInfoActionTypes.FETCH_LEGAL_INFO_FAILED
)<string, DipLegalInfo, Error>();

export const GetLegalInfoData = (): FetchLegalInfo => action(LegalInfoActionTypes.FETCH_LEGAL_INFO);

export type LegalInfoActionType = FetchLegalInfo | FetchLegalInfoSucceed | FetchLegalInfoFailed;