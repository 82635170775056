import { action, createAsyncAction } from 'typesafe-actions';
import { Token, TokenActionTypes } from './types';

export interface SetAccessToken {
	type: TokenActionTypes.SET_ACCESS_TOKEN;
	payload: string;
}
export interface ClearToken {
	type: TokenActionTypes.CLEAR_TOKEN;
}

export interface ProcessToken {
	type: TokenActionTypes.PROCESS_TOKEN;
	payload: string;
}

export interface ProcessTokenSuceeded {
	type: TokenActionTypes.PROCESS_TOKEN_SUCCEEDED;
	payload: Token;
}

export interface ProcessTokenFailed {
	type: TokenActionTypes.PROCESS_TOKEN_FAILED;
	payload: Error;
}

export const ProcessToken = createAsyncAction(
	TokenActionTypes.PROCESS_TOKEN,
	TokenActionTypes.PROCESS_TOKEN_SUCCEEDED,
	TokenActionTypes.PROCESS_TOKEN_FAILED
)<string, Token, Error>();

export const SetAccessToken = (acessToken: string): SetAccessToken => {
	return action(TokenActionTypes.SET_ACCESS_TOKEN, acessToken)
};

export const ClearToken = (): ClearToken => {
	return action(TokenActionTypes.CLEAR_TOKEN);
};

export type TokenActionType = SetAccessToken | ClearToken | ProcessToken | ProcessTokenSuceeded | ProcessTokenFailed;
