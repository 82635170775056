import { takeLatest, all, put, fork } from 'redux-saga/effects';
import { SetCultures, CultureChange } from './actions';
import { getCultures, CultureApi } from '../../api/localization';
import { CulturesActionType } from './types';
import { getCurrentCulture } from '../../utils/culture';
function* loadCultures() {
    try {
      const cultures: CultureApi = yield getCultures();
      yield put(SetCultures(cultures.items));
      const currentCulture = getCurrentCulture(cultures.items);
      yield put(CultureChange(currentCulture));
    }
    catch {
      yield put(SetCultures([{id: 'en', text: 'English', has_been_translated: true}]));  
      yield put(CultureChange('en'));
    }

}

function* watchLoadCultures() {
    yield takeLatest(CulturesActionType.GET_CULTURES, loadCultures);
}

export default function* culturesSagas() {
    yield all([
        fork(watchLoadCultures)
    ]);
}