import React, { ReactNode } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme } from '@material-ui/core/styles';
import InformationURL from '../../graphics/Information.svg';
import InformationHoverURL from '../../graphics/InformationHover.svg';
import classNames from 'classnames';

const useStyles = (_theme: Theme): any => ({
	infoIconContainer: {
		verticalAlign: 'baseline',
		lineHeight: '12px',
		display: 'inline-table',
		marginLeft: '3px',
		marginTop: 1,
	},
	infoIcon: {
		width: '12px',
	},
	withHover: {
		'& img:nth-child(even)': {
			display: 'none',
		},
		'& img:nth-child(odd)': {
			display: 'block',
		},
		'&:hover img:nth-child(even)': {
			display: 'block',
		},
		'&:hover img:nth-child(odd)': {
			display: 'none',
		},
	},
});

type ToolTipProps = {
	toolTip: NonNullable<ReactNode>;
	classes: any;
	alwaysRed?: boolean;
};

const ToolTip: React.FC<ToolTipProps> = props => {
	const { toolTip, classes, alwaysRed } = props;
	const hoverNeeded = alwaysRed === undefined;

	const IconWrapper = React.forwardRef<HTMLDivElement>((properties, ref) => (
		<div {...properties} ref={ref}>
			<img
				src={InformationURL}
				className={classes.infoIcon}
				alt="Tooltip icon"
			/>
			<img
				src={InformationHoverURL}
				className={classes.infoIcon}
				alt="Tooltip icon"
			/>
		</div>
	));

	return (
		<span
			key="tooltip"
			className={classNames(
				hoverNeeded ? classes.withHover : '',
				classes.infoIconContainer
			)}
		>
			<Tooltip
				placement="top-start"
				enterTouchDelay={0}
				title={toolTip}
				className="MuiTooltip"
			>
				{hoverNeeded ? (
					<IconWrapper />
				) : (
					<img
						src={InformationHoverURL}
						className={classes.infoIcon}
						alt="Tooltip icon"
					/>
				)}
			</Tooltip>
		</span>
	);
};

export default withStyles(useStyles, { withTheme: true })(ToolTip);
