import React from 'react';
import { FieldUi, InputMode } from '../../FieldUi';
import { Field, WrappedFieldProps } from 'redux-form';
import { FormControl, Grid, InputLabel, Input } from '@material-ui/core';
import renderFromHelper from '../renderFromHelper';
import { getTranslationUi } from '../../../TranslationUi';
import { FieldTooltip } from '../FieldTooltip/FieldTooltip';

type TextFieldProps = {
	label: string;
	name: string;
	type: string;
	placeholder: string;
	maxLength: number;
	disabled: boolean;
	required: boolean;
	inputMode?: InputMode;
} & WrappedFieldProps;

export const RenderTextFieldControl = ({
	input,
	label,
	type,
	meta: { touched, error, warning },
	name,
	maxLength,
	disabled,
	required,
	inputMode,
	...custom
}: TextFieldProps) => {
	const id = name || input.name;
	return (
		<Grid className="MuiFormItem">
			<FormControl
				error={(touched && !!error) || !!warning}
				fullWidth
				required={required}
				disabled={disabled}
			>
				<Grid className={'MuiAlignMessage'}>
					<InputLabel shrink htmlFor={id} disabled={disabled}>
						{label} <FieldTooltip fieldName={id} />
					</InputLabel>
					{renderFromHelper({ touched, error, warning })}
				</Grid>
				<Grid item>
					<Input
						error={touched && !!error}
						required={required}
						type={type}
						{...input}
						{...custom}
						inputProps={{
							id,
							maxLength,
							name: id,
							inputMode: inputMode
						}}
						fullWidth
					/>
				</Grid>
			</FormControl>
		</Grid>
	);
};

const renderTextField: React.FC<FieldUi> = (props, loading) => {
	return (
		<Field
			name={props.name}
			label={getTranslationUi(
				`user_profile.${props.name}.label`,
				props.label.defaultText
			)}
			type={props.data_type}
			component={RenderTextFieldControl}
			maxLength={props.validation.max_len_fe || props.validation.max_len}
			fullWidth
			required={props.required}
			disabled={loading || props.disabled}
			inputMode={props.inputMode || undefined}
		/>
	);
};

export default renderTextField;
