import { ReferenceItemApiModel } from '../../api/referencesApi';

export enum ReferencesActionTypes {
	SET_LANGUAGES_NEEDED = '@@references/SET_LANGUAGES_NEEDED',
	GET_LANGUAGES = '@@references/GET_LANGUAGES',
	GET_LANGUAGES_SUCCEED = '@@references/GET_LANGUAGES_SUCCEED',

	SET_DEPARTMENTS_NEEDED = '@@references/SET_DEPARTMENTS_NEEDED',
	GET_DEPARTMENTS = '@@references/GET_DEPARTMENTS',
	GET_DEPARTMENTS_SUCCEED = '@@references/GET_DEPARTMENTS_SUCCEED',

	SET_COMPANY_TYPES_NEEDED = '@@references/SET_COMPANY_TYPES_NEEDED',
	GET_COMPANY_TYPES = '@@references/GET_COMPANY_TYPES',
	GET_COMPANY_TYPES_SUCCEED = '@@references/GET_COMPANY_TYPES_SUCCEED',

	SET_AREAS_OF_INTEREST_NEEDED = '@@references/SET_AREAS_OF_INTEREST_NEEDED',
	GET_AREAS_OF_INTEREST = '@@references/GET_AREAS_OF_INTEREST',
	GET_AREAS_OF_INTEREST_SUCCEED = '@@references/GET_AREAS_OF_INTEREST_SUCCEED',

	SET_AREA_OF_INTEREST_NEEDED = '@@references/SET_AREA_OF_INTEREST_NEEDED',
	GET_AREA_OF_INTEREST = '@@references/GET_AREA_OF_INTEREST',
	GET_AREA_OF_INTEREST_SUCCEED = '@@references/GET_AREA_OF_INTEREST_SUCCEED',
}

export interface ReferencesAppStateModel
	extends LanguagesAppStateModel,
		DepartmentsAppStateModel,
		AreasOfInterestAppStateModel,
		AreaOfInterestAppStateModel,
		CompanyTypesAppStateModel {}

interface LanguagesAppStateModel {
	languages?: Language[];
	languagesNeeded?: boolean;
}

export interface Language {
	id: string;
	text: string;
	has_been_translated: boolean;
}

export interface DepartmentsAppStateModel {
	departments?: ReferenceItemApiModel[];
	departmentsNeeded?: boolean;
}

export interface AreasOfInterestAppStateModel {
	areasOfInterest?: ReferenceItemApiModel[];
	areasOfInterestNeeded?: boolean;
}

export interface AreaOfInterestAppStateModel {
	areaOfInterest?: ReferenceItemApiModel[];
	areaOfInterestNeeded?: boolean;
}

export interface CompanyTypesAppStateModel {
	companyTypes?: ReferenceItemApiModel[];
	companyTypesNeeded?: boolean;
}
