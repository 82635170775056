import fetchApiWrapper from './fetchApiWrapper';
import { Language } from '../store/references/types';

export interface ReferenceApiModel<T = ReferenceItemApiModel> {
	items?: T[];
}

export interface ReferenceItemApiModel {
	id: string;
	text: string;
}

export const getDepartments = (directEndpoint: string, pageLanguage?: string) =>
	getReferenceData(directEndpoint, pageLanguage);

export const getLanguages = (directEndpoint: string, pageLanguage?: string) =>
	getReferenceData<ReferenceApiModel<Language>>(directEndpoint, pageLanguage);

export const getCompanyTypes = (
	directEndpoint: string,
	pageLanguage?: string
) => getReferenceData(directEndpoint, pageLanguage);

export const getAreasOfInterest = (
	directEndpoint: string,
	pageLanguage?: string
) => getReferenceData(directEndpoint, pageLanguage);

export const getReferenceData = <T = ReferenceApiModel>(
	endpoint: string,
	pageLanguage?: string
): Promise<T> => {
	const requestHeaders = new Headers();
	requestHeaders.set(
		'Content-Type',
		'application/json-patch+json; charset=utf-8'
	);

	if (pageLanguage) {
		requestHeaders.append('Accept-Language', pageLanguage);
	}

	return fetchApiWrapper<T>(endpoint, {
		headers: requestHeaders,
		method: 'GET',
	});
};
