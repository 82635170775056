import createHmac from 'create-hmac';

export const getRegistrationCenterApiKey = (hashKey:string) => {
  const hmac = createHmac('sha256', hashKey);
  hmac.update(getUtcDate(Date.now()));
  return hmac.digest('hex')
}

export const getUtcDate = (date: Date | number) : string => {
  return (typeof(date) === 'number' && new Date(date) || date as Date).toISOString().substring(0, 10).replace(/-/g, "")
}
