export default <T>(input: RequestInfo, init?: RequestInit): Promise<T> =>
	fetch(input, init).then(response => {
		if (!response.ok) {
			return response
				.json()
				.then(data => {
					// eslint-disable-next-line
					throw { response, ...data };
				})
				.catch(data => {
					// eslint-disable-next-line
					throw { response, ...data };
				});
		}
		return response
			.json()
			.then(data => data as T)
			.catch(() => (response as unknown) as T);
	});
