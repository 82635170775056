import React from 'react';
import { getTranslationUi } from '../../../TranslationUi';
import ToolTip from '../../../../ToolTip/ToolTip';

const FieldTooltip: React.FC<{ fieldName: string }> = React.memo(props => {
	const { fieldName } = props;

	const tooltip = getTranslationUi(`user_profile.${fieldName}.tooltip`);
	if (!tooltip) return null;

	return <ToolTip key={fieldName} toolTip={tooltip} />;
});

export { FieldTooltip };
