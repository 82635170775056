import { action, createAsyncAction } from 'typesafe-actions'
import { Profile, ProfileActionTypes } from './types';
import { UserApiModel } from '../../api/userProfileDataApi';
import { NavigateFunction } from 'react-router-dom';

interface UpdateUserRequestPayload {
	profile: Partial<Profile>,
	navigate: NavigateFunction
}
export interface UpdateUserRequest {
	type: ProfileActionTypes.UPDATE_USER;
	payload: UpdateUserRequestPayload
}

export interface UpdateUserFailed {
	type: ProfileActionTypes.UPDATE_USER_FAILED;
	payload: Error;
}

export interface UpdateUserSucceed {
	type: ProfileActionTypes.UPDATE_USER_SUCCEED;
	payload: Partial<Profile>;
}

type UpdateUser = UpdateUserRequest | UpdateUserFailed | UpdateUserSucceed;

export const UpdateUser = createAsyncAction(
	ProfileActionTypes.UPDATE_USER,
	ProfileActionTypes.UPDATE_USER_SUCCEED,
	ProfileActionTypes.UPDATE_USER_FAILED
)<UpdateUserRequestPayload, Partial<Profile>, Error>();

export interface FetchUserRequest {
	type: ProfileActionTypes.FETCH_USER;
}

export interface FetchUserSucceed {
	type: ProfileActionTypes.FETCH_USER_SUCCEED;
	payload: UserApiModel; 
}

export interface FetchUserFailed {
	type: ProfileActionTypes.FETCH_USER_FAILED;
	payload: Error;
}

type FetchUser = FetchUserRequest | FetchUserSucceed | FetchUserFailed;


export const FetchUser = createAsyncAction(
	ProfileActionTypes.FETCH_USER,
	ProfileActionTypes.FETCH_USER_SUCCEED,
	ProfileActionTypes.FETCH_USER_FAILED
)<string, UserApiModel, Error>();

export interface ProgressiveProfilingSucceded {
	type: ProfileActionTypes.PROGRESSIVE_PROFILING_SUCCEED;
}

export const ProgressiveProfilingSucceded = (): ProgressiveProfilingSucceded => action(ProfileActionTypes.PROGRESSIVE_PROFILING_SUCCEED);

export type UserActionType = FetchUser | UpdateUser | ProgressiveProfilingSucceded;
