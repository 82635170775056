import i18next from 'i18next';
import { ReactNode } from 'react';
import parse from 'html-react-parser';

export interface TranslationUi {
	translationKey?: string;
	defaultText?: string;
	options?: any;
}

export const getTranslationUi = (
	translationKey: string,
	defaultText?: string,
	options?: any
) => {
	return (i18next.exists(translationKey)
		? parse(i18next.t(translationKey, options))
		: defaultText || '') as NonNullable<ReactNode>;
};

export const getTranslationText = (
	translationKey: string,
	defaultText?: string
) => {
	return (i18next.exists(translationKey)
		? i18next.t(translationKey)
		: defaultText || '');
};

