import { all, fork, takeEvery, call, put, select } from 'redux-saga/effects';
import { AppState } from '../index';
import {
	getApplicationData,
	ApplicationApiModel,
} from '../../api/applicationDataApi';
import { FetchData } from './actions';
import { getType } from 'typesafe-actions';
import {
	ProcessToken,
	ProcessTokenFailed,
	ProcessTokenSuceeded,
} from '../token/actions';
import { Token } from '../token/types';
import Logger from '../../components/Logger/Logger';
import { LoadingErrorAction } from '../loading/actions';
import { FieldMetadata } from '../fieldsMetadata/types';

function* fetchApplication(action: ProcessTokenFailed | ProcessTokenSuceeded) {
	try {
		const token: Token = yield select((state: AppState) => state.token);
		const fieldsConfig: FieldMetadata[] = yield select((state: AppState) => state.fieldsMetadata.fields);

		if (
			action.type === getType(ProcessToken.failure) ||
			!token ||
			!token.accessToken
		) {
			const error = new Error('Token is not valid to retrieve application');
			yield put(FetchData.failure(error));
			yield put(LoadingErrorAction(error));
			return;
		}

		const currentCulture: string = yield select(
			(state: AppState) => state.cultures.currentCulture
		);

		const response: ApplicationApiModel = yield call(
			getApplicationData,
			token.accessToken,
			token.clientId,
			currentCulture
		);

		if (!response) {
			const msg = `Client metadata is empty`;
			const error = new Error(msg);
			yield put(LoadingErrorAction(error));
			const ErrorLogger = new Logger();
			ErrorLogger.error(`Client metadata is empty`);
			return;
		}

		if (!response.fields) {
			response.fields = [];
		}

		// fields config mutation!
		response.fields.forEach(appField => {
			const configFields = fieldsConfig.filter(
				confField => confField.name === appField.name
			);
			if (!configFields.length) {
				const ErrorLogger = new Logger();
				ErrorLogger.warn(
					`Field  ${JSON.stringify(
						appField
					)} is not supported. Please add it into FieldsConfig`
				);
				return;
			}

			if (configFields.length > 1) {
				const ErrorLogger = new Logger();
				ErrorLogger.warn(
					`Found multiple configs in FieldsConfig for ${JSON.stringify(
						appField
					)}`
				);
				return;
			}

			const configField = configFields[0];
			configField.idp_data_path = appField.idp_data_path;
			configField.display_name = appField.display_name;
			configField.api_url = appField.api_url;
			configField.is_multi_select = appField.is_multi_select;
			configField.required = !appField.is_optional;
			configField.validation = {
				...configField.validation,
				...appField.validation,
			};
		});

		yield put(FetchData.success(response));
	} catch (err: any) {
		yield put(FetchData.failure(err));
		yield put(LoadingErrorAction({ message: err.message, response: err }));
	}
}

function* watchApplicationFetch() {
	yield takeEvery(
		[getType(ProcessToken.success), getType(ProcessToken.failure)],
		fetchApplication
	);
}

function* applicationSagas() {
	yield all([fork(watchApplicationFetch)]);
}

export default applicationSagas;
