import React from "react";
import { GroupUi } from "../GroupUi";
import FieldRenderer from "../../FormFields/FieldRenderer";

interface Group extends GroupUi { }

const GeneralGroup: React.FC<Group> = (props) => {
    if (!props.fieldsUi || !props.fieldsUi.length) {
        return null;
    }
    return <React.Fragment>
        <FieldRenderer loading={props.loading} fields={props.fieldsUi} layout={props.layout || 'vertical'} />
    </React.Fragment>;
};

export default GeneralGroup;