interface AppSettings {
	auth0: {
		domain: string
	},
	backend: {
		myaccount: string;
		regcenter: string;
		hashKey: string;
	},
	appInsights: {
		key: string;
	},
	staticFiles: {
		endpoint: string;
	},
	application: {
		url: string;
	},
	ui: {
		logoUrl: string;
	},
	cookieinformation: {
		id: string;
		url: string;
	},
	footerLinks: {
		generalInformationURL: string,
		faqURL: string
	},
	googleTagManager: {
		id: string;
	},
	googleAnalytics: {
		id: string;
	},
	releaseNumber: {
		value: string
	},
	embargoRestrictions: {
	  countries: string,
	  regions: string
	}
}
const settings: AppSettings = {
	auth0: JSON.parse(process.env.REACT_APP_AUTH0 || '{}'),
	backend: JSON.parse(process.env.REACT_APP_BACKEND || '{}'),
	appInsights: JSON.parse(process.env.REACT_APP_APPINSIGHTS || '{}'),
	staticFiles: JSON.parse(process.env.REACT_APP_STATICFILES || '{}'),
	application: JSON.parse(process.env.REACT_APP_APPLICATION || '{}'),
	ui: JSON.parse(process.env.REACT_APP_UI || '{}'),
	cookieinformation: JSON.parse(
		process.env.REACT_APP_COOKIEINFORMATION || '{}'
	),
	footerLinks: JSON.parse(process.env.REACT_APP_FOOTER_LINKS || '{}'),
	googleTagManager: JSON.parse(process.env.REACT_APP_GOOGLETAGMANAGER || '{}'),
	googleAnalytics: JSON.parse(process.env.REACT_APP_GOOGLEANALYTICS || '{}'),
	releaseNumber: JSON.parse(process.env.REACT_APP_RELEASENUMBER || ""),
	embargoRestrictions: JSON.parse(process.env.REACT_APP_EMBARGO || "")
};

export default settings;
