import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';

import { Spinner } from '@danfoss/webex-ui';

import { UserActionType } from '../../store/profile/actions';
import { ApplicationActionType } from '../../store/application/actions';
import { AppState } from '../../store';

import queryString from 'query-string';
import { ProcessToken, TokenActionType } from '../../store/token/actions';
import Logger from '../../components/Logger/Logger';
import { useLocation } from 'react-router';

const mapStateToProps = (state: AppState) => ({
	profile: state.profile,
	tokens: state.token,
	application: state.application,
});

const mapDispatchToProps = (
	dispatch: Dispatch<UserActionType | ApplicationActionType | TokenActionType>
) => ({
	tokenProcessRequest: (token: string) => dispatch(ProcessToken.request(token))
});

type Props = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> & {
		optionalAuthentication?: boolean;
		children: React.ReactElement;
	};

const ProtectedRoute: React.FC<Props> = props => {
	const {
		tokens,
		profile,
		optionalAuthentication,
		children,
		application,
		tokenProcessRequest,
		...rest
	} = props;

	const location = useLocation();
	const values: {
		token: string;
		state: string;
	} = queryString.parse(location.search) as any;
	useEffect(() => {
		if (!values.token || !values.state) {
			const ErrorLogger = new Logger();
			ErrorLogger.error(
				"Token and State should be defined in Query parameters as 'token' and 'state'"
			);
			return;
		}

		tokenProcessRequest(values.token);

	}, [values.token, values.state, tokenProcessRequest]);

	if (application && profile) {
		return children;
	}
	else {
		return (
			<div className="spinner_white">
				<Spinner visible={true} />
			</div>
		);
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
