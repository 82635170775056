import { action } from 'typesafe-actions';
import { FieldsMetadataActionType, FieldsMetadata } from './types';

export interface SetupFieldsMetadata {
	type: FieldsMetadataActionType.SET_FIELDSMETADATA;
	payload: FieldsMetadata;
}

export const SetFieldsMetadata = (fieldsConfig: FieldsMetadata): SetupFieldsMetadata =>
	action(FieldsMetadataActionType.SET_FIELDSMETADATA, fieldsConfig);

export const SetFieldDataSuccess = () => 
action(FieldsMetadataActionType.SET_FIELDSMETADATA_SUCCESS);

export type FieldsMetadataActionTypes = SetupFieldsMetadata;
