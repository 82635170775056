import { TokenActionTypes, Token } from './types';
import { TokenActionType } from './actions';
import { createReducer } from 'typesafe-actions';
import URLSearchParams from '@ungap/url-search-params';
import Logger from '../../components/Logger/Logger';

const initialState = {} as Token;

const reducer = createReducer<Token, TokenActionType>(initialState, {
	[TokenActionTypes.SET_ACCESS_TOKEN]: (state, action) => {
		const newToken = action.payload;
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set('token', newToken);
		window.history.replaceState(
			{},
			'',
			`${window.location.pathname}?${searchParams}`
		);
		return { ...state, ...{ accessToken: action.payload } };
	},
	[TokenActionTypes.PROCESS_TOKEN_SUCCEEDED]: (state, action) => {
		return { ...state, ...action.payload };
	},
	[TokenActionTypes.PROCESS_TOKEN_FAILED]: (_, action) => {
		const ErrorLogger = new Logger();
		ErrorLogger.error(action.payload.message);
		return {} as Token;
	},
	[TokenActionTypes.CLEAR_TOKEN]: () => {
		return {} as Token;
	}
});

export { reducer as tokensReducer };
