import { Culture } from '../store/culture/types';
import { Profile } from '../store/profile/types';


export const getCurrentCulture = (cultures: Culture[], profile?: Profile) => {
	const uiLocale = new URLSearchParams(window.location.search).get(
		'ui_locales'
	);

	const navigatorLanguage =
		cultures &&
		cultures.find(
			(c: Culture) =>
				c.id === navigator.language ||
				c.id.indexOf(navigator.language.split('-')[0]) !== -1
		);

	const trueCulture =
		languageHasBeenTranslated(cultures, uiLocale) ||
		languageHasBeenTranslated(
			cultures,
			profile && profile.language && profile.language.id
		) ||
		languageHasBeenTranslated(
			cultures,
			navigatorLanguage && navigatorLanguage.id
		) ||
		'en';

	return trueCulture;
};

const languageHasBeenTranslated = (
	cultures: Culture[],
	lang?: string | null
): string | undefined => {
	if (!lang) return undefined;

	const availableCulture =
		cultures &&
		cultures.find((c: Culture) => c.id.toLowerCase() === lang.toLowerCase() && c.has_been_translated);

	return availableCulture && availableCulture.id;
};
