import { takeLatest, all, put, fork, select, call } from 'redux-saga/effects';
import {
	SetCountries,
	GET_COUNTRIES_TRANSLATION,
	SetCountriesTranslation,
	GET_STATES,
	SetStates,
	SET_COUNTRIES_NEEDED,
	GetCountriesTranslation,
	SET_CALLING_CODES_NEEDED,
	SetCallingCodes,
	SetTimezones,
	SET_TIMEZONES_NEEDED,
} from './actions';
import {
	getCountries,
	getCountriesTranslations,
	getTimezones,
	getStates,
	LocationApiCountries,
	getCallingCodes,
	LocationApiCallingCodes,
	LocationApiCallingCode,
	LocationApiCallingCodeValue,
	LocationApiTimezone,
	LocationApiTimezones,
	LocationApiCountryStates,
} from '../../api/locationApi';
import { LocationActionTypes } from './types';
import { CulturesActionType } from '../culture/types';
import { AppState } from '../';

const compareTimeZones = (t1: LocationApiTimezone, t2: LocationApiTimezone) => {
    
    const t1Offset = parseFloat(t1.base_utc_offset.replace(/:/g, ".")) || 0;
    const t2Offset = parseFloat(t2.base_utc_offset.replace(/:/g, ".")) || 0;

    if (t1Offset === t2Offset) {
        return t1.name.localeCompare(t2.name);
    } 

    return t2Offset < t1Offset ? 1 : -1;
}

function* loadTimezones() {

	const appState: AppState = yield select(
		(state: AppState) => state
	);
	if (
		!appState.location.timezonesNeeded ||
		(appState.location.timezones && appState.location.timezones.length) ||
		!appState.token
	) {
		return;
	}

	let timezones: LocationApiTimezones = yield getTimezones(appState.token.accessToken);

	const timezoneFormattet = (timezones && timezones.value && [...timezones.value].sort((a, b) => compareTimeZones(a, b)).map(timezone => { 
        return {id: timezone.id, text: timezone.name};
    }, [])) || [];

	yield put(SetTimezones(timezoneFormattet));
}

function* loadCountries() {

	const appState: AppState = yield select(
		(state: AppState) => state
	);
	if (
		!appState.location.countriesNeeded ||
		(appState.location.countries && appState.location.countries.length) ||
		!appState.token
	) {
		return;
	}

	let countries: LocationApiCountries = yield getCountries(appState.token.accessToken);
	
	const currentCulture: string = yield select(
		(s: AppState) => s.cultures.currentCulture
	);
	yield call(loadCountriesTranslation, GetCountriesTranslation(currentCulture));
	yield put(SetCountries(countries.value));
}

function* loadCountriesTranslation(action: GET_COUNTRIES_TRANSLATION) {
	const appState: AppState = yield select(
		(state: AppState) => state
	);
	if (
		!appState.location.countriesNeeded ||
		(appState.location.countriesTranslations &&
			appState.location.countriesTranslations[action.payload]) ||
		!appState.token?.accessToken	
	) {
		return;
	}

	const translations: LocationApiCountries = yield getCountriesTranslations(appState.token.accessToken, action.payload);
	yield put(SetCountriesTranslation(action.payload, translations.value));
}

function* loadStates(action: GET_STATES) {
	const appState: AppState = yield select(
		(state: AppState) => state
	);
	if (!appState.location.statesNeeded || (appState.location.states && appState.location.states.length) || !appState.token) {
		return;
	}

	const currentCulture: string = yield select(
		(s: AppState) => s.cultures.currentCulture
	);

	const states: LocationApiCountryStates = yield getStates(appState.token.accessToken, action.payload, currentCulture);

	yield put(SetStates(action.payload, states.value[0].divisions));
}

function* watchLoadCountries() {
	yield takeLatest(LocationActionTypes.GET_COUNTRIES, loadCountries);
	yield takeLatest(LocationActionTypes.SET_COUNTRIES_NEEDED, function*(
		action: SET_COUNTRIES_NEEDED
	) {
		action.payload && (yield loadCountries());
	});
}

function* watchLoadTimezones() {
	yield takeLatest(LocationActionTypes.GET_TIMEZONES, loadTimezones);
	yield takeLatest(LocationActionTypes.SET_TIMEZONES_NEEDED, function*(
		action: SET_TIMEZONES_NEEDED
	) {
		action.payload && (yield loadTimezones());
	});
}

function* loadCallingCodes() {

	const appState: AppState = yield select(
		(state: AppState) => state
	);
	if (
		!appState.location.callingCodesNeeded ||
		(appState.location.callingCodes && appState.location.callingCodes.length) ||
		!appState.token
	) {
		return;
	}

	const callingCodes: LocationApiCallingCodes = yield getCallingCodes(appState.token.accessToken, appState.cultures.currentCulture);

	let callingCodesFormatted:LocationApiCallingCode[] = [];

	callingCodes &&
	callingCodes.value.forEach((cc: LocationApiCallingCodeValue) => {
		cc.calling_codes.forEach(callingCodeValue => {
			if (callingCodeValue.indexOf(";") > -1) {
				const callingCodeValues = callingCodeValue.split(";");
				callingCodeValues.forEach(ccValue => {
					callingCodesFormatted.push({ countryCode: cc.alpha2, countryName: cc.name, callingCode: ccValue }); 
				})
			} else {
				callingCodesFormatted.push({ countryCode: cc.alpha2, countryName: cc.name, callingCode: callingCodeValue}); 
			}
		})
	});

	yield put(SetCallingCodes(appState.cultures.currentCulture, callingCodesFormatted));
}

function* watchCallingCodes() {
	yield takeLatest(LocationActionTypes.GET_CALLING_CODES, loadCallingCodes);
	yield takeLatest(LocationActionTypes.SET_CALLING_CODES_NEEDED, function*(
		action: SET_CALLING_CODES_NEEDED
	) {
		action.payload && (yield loadCallingCodes());
	});
}

function* watchLoadCountriesTranslation() {
	yield takeLatest(
		LocationActionTypes.GET_COUNTRIES_TRANSLATIONS,
		loadCountriesTranslation
	);
}

function* watchCultureChange() {
	yield takeLatest(CulturesActionType.CULTURE_CHANGE, loadCountriesTranslation);
}

function* watchLoadStates() {
	yield takeLatest(LocationActionTypes.GET_STATES, loadStates);
}

export default function* culturesSagas() {

	yield all([
		fork(watchLoadCountries),
		fork(watchLoadTimezones),
		fork(watchLoadCountriesTranslation),
		fork(watchCultureChange),
		fork(watchLoadStates),
		fork(watchCallingCodes),
	]);
}
