import { FieldsMetadataActionType, FieldsMetadata, FieldMetadata } from './types';
import { createReducer } from 'typesafe-actions';
import { FieldsMetadataActionTypes } from './actions';

const fieldsConfig = (obj:FieldsMetadata):any => {

	let fieldsConfigObject:any = [];

	obj.fields.forEach((field: FieldMetadata) => {
		
		fieldsConfigObject = {...fieldsConfigObject, [field.name]:field}
	});

	return fieldsConfigObject;
}

const reducer = createReducer<FieldsMetadata, FieldsMetadataActionTypes>({
	fields: [] as FieldMetadata[],
	fieldsObject: {} as any
}, {
	[FieldsMetadataActionType.SET_FIELDSMETADATA]: (state: FieldsMetadata, action) => {
		return { ...action.payload,  fieldsObject: {...fieldsConfig(action.payload)}};
	}
});

export { reducer as fieldsMetadataReducer };
