import { ProggresiveFieldsActionTypes, ProggresiveFields } from './types';
import { ProgressiveFieldsActionType } from './actions';
import { createReducer } from 'typesafe-actions';

export const initialState: ProggresiveFields = {
	fields: [],
};

const reducer = createReducer<ProggresiveFields, ProgressiveFieldsActionType>(
	initialState
, {
	[ProggresiveFieldsActionTypes.UPDATE]: (state, action) => {
	return { ...state, fields: action.payload };
}
});

export { reducer as progressiveFieldsReducer };
