import React, { useEffect } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import settings from '../../config/settings';
import { getCookieCategoriesAcceptance } from './CookieInformation';
import { CookieRemove } from '../../utils/cookies';

interface Props {
	history: any;
}

export const TelemetryService: React.FC<Props> = ({ history }) => {
    
    useEffect(() => {
        if (!settings.appInsights.key) {
            return;
        }

        let reactPlugin = new ReactPlugin();
        let appInsights: ApplicationInsights | null;

        // When cookieinformation is loaded we need to trigger this function
        window.addEventListener('CookieInformationConsentGiven', function (event:any) { 

            event = {...event, origin: (event?.target?.location?.origin || '')}

            if (event.origin !== settings.application.url) {
                return 
            }

            // Get user cookie acceptance from cookieinformation
            const acceptance = getCookieCategoriesAcceptance();
            
            // If the user has accepted statistic cookies we can continue
            if (acceptance && acceptance.statistic) { 
                (function () { 

                    appInsights = new ApplicationInsights({
                        config: {
                            instrumentationKey: settings.appInsights.key,
                            maxBatchInterval: 0,
                            disableFetchTracking: false,
                            extensions: [reactPlugin],
                            extensionConfig: {
                                [reactPlugin.identifier]: history
                            }
                        }
                    });

                    appInsights.loadAppInsights();

                })(); 
            } else {
                // Set appInsigts to null, so that we can reinstantiate if the user allows statistical cookies
				if (appInsights) {
					appInsights.stopTrackPage && appInsights.stopTrackPage();
					appInsights.clearAuthenticatedUserContext &&
						appInsights.clearAuthenticatedUserContext();
				}
				appInsights = null;

                // Delete application insights cookies
                CookieRemove('ai_authUser');
				CookieRemove('ai_session');
				CookieRemove('ai_user');
            }
        }, false); 
	}, []);

    return null;
} 