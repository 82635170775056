import {
	Store,
	createStore,
	applyMiddleware,
	compose,
	Reducer,
	AnyAction,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import persistState, { mergePersistedState } from 'redux-localstorage';
import adapterLS from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import reduxThunk from 'redux-thunk';
import rootSage from './store/sagas';

import { AppState, createRootReducer } from './store';
import { FormStateMap } from 'redux-form';
import { Token } from './store/token/types';
import { Loading } from './store/loading/types';
import { Profile } from './store/profile/types';
import { ApplicationApiModel, DipLegalInfo } from './api/applicationDataApi';
import { Cultures } from './store/culture/types';
import { LocationAppStateModel } from './store/location/types';
import { ProggresiveFields } from './store/progressiveFields/types';
import { ReferencesAppStateModel } from './store/references/types';
import { FieldsMetadata } from './store/fieldsMetadata/types';

export default function configureStore(): Store<AppState> {
	const composeEnhancers =
		(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const sagaMiddleware = createSagaMiddleware();
	const middlewears = [sagaMiddleware, reduxThunk];

	const storageToken = compose(filter(['tokens']))(
		adapterLS(window.localStorage)
	);

	// Temporary solution to handle breaking changes in the way countriesTralations are stored in state. Can be removed in next release	
	const location = sessionStorage.getItem('location') && JSON.parse(sessionStorage.getItem('location') || '{}');
	if (location && location.location && location.location.countriesTranslations && 
		Object.keys(location.location.countriesTranslations).length > 0 && location.location.countriesTranslations[Object.keys(location.location.countriesTranslations)[0]].value) {
			sessionStorage.removeItem('location');
	}

	const storageLocation = compose(filter(['location']))(
		adapterLS(window.sessionStorage)
	);

	const storageReferences = compose(filter(['references']))(
		adapterLS(window.sessionStorage)
	);

	const reducer = compose(mergePersistedState())(
		createRootReducer()
	) as Reducer<
		{
			form: FormStateMap;
			profile: Profile;
			loading: Loading;
			token: Token;
			application: ApplicationApiModel;
			cultures: Cultures;
			location: LocationAppStateModel;
			progressiveFields: ProggresiveFields;
			legalInfo: DipLegalInfo;
			references: ReferencesAppStateModel;
			fieldsMetadata: FieldsMetadata;
		},
		AnyAction
	>;

	const store = createStore(
		reducer,
		composeEnhancers(
			applyMiddleware(...middlewears),
			persistState(storageToken, 'token'),
			persistState(storageLocation, 'location'),
			persistState(storageReferences, 'references')
		)
	);

	sagaMiddleware.run(rootSage);

	return store;
}
