import React, {useState, useEffect} from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import Backend from 'i18next-xhr-backend';
import settings from '../../config/settings';

interface LocalizationManagerProps {
  children: React.ReactNode
}

type Props = LocalizationManagerProps & ReturnType<typeof mapStateToProps>;

const LocalizationManager: React.FC<Props> = ({currentCulture, children}) => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
      // Initalize i18n firsst time the currentCulture is set
      if (currentCulture && !ready) {
        i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
          lng: currentCulture,
          fallbackLng: "en",
          debug: false,
          ns: ["translations"],
          defaultNS: "translations",
          partialBundledLanguages: true,
          initImmediate: false,
          interpolation: {
            escapeValue: false,
            formatSeparator: ","
          },  
          react: {
            useSuspense: true,
          },
          backend: {
            loadPath: `${settings.staticFiles.endpoint}/localization/translations/{{lng}}.json?release=${settings.releaseNumber.value}`,
          },
        });
        setReady(true);
      }
      
      // Change i18n language when currentCulture changes
      if (ready && currentCulture !== i18n.language) {
        i18n.changeLanguage(currentCulture);
      }

    },[currentCulture]);

    if (!ready) 
      return null;

    return (
      <I18nextProvider i18n={i18n}>
          {children}
      </I18nextProvider>
    );

}

const mapStateToProps = (state: AppState)  => {
  const { cultures } = state;
  const { currentCulture } = cultures;
  return {
    currentCulture: currentCulture
  }
};

export default connect(mapStateToProps)(LocalizationManager);
