import React, {Suspense} from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { AppState } from '../../store';
import Routes from '../../routes';
import { BrowserRouter } from 'react-router-dom';
import LocalizationManager from './LocalizationManager';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import theme from './MuiTheme';
import { ThemeProvider } from '@material-ui/styles';
import Title from './Title';
import { Spinner } from '@danfoss/webex-ui';

interface MainProps {
	store: Store<AppState>;
}

const Main: React.FC<MainProps> = ({ store }) => {
	return (
		<Provider store={store}>
			<LocalizationManager>
				<Suspense fallback={<Spinner visible={true} /> }>
					<Title />
					<MuiThemeProvider>
						<ThemeProvider theme={theme}>
							<BrowserRouter>
								<Routes />
							</BrowserRouter>
						</ThemeProvider>
					</MuiThemeProvider>
				</Suspense>	
			</LocalizationManager>
				
		</Provider>
	);
};

export default Main;
