import fetchApiWrapper from './fetchApiWrapper';
import settings from '../config/settings';
import { FieldsMetadata } from '../store/fieldsMetadata/types';
import { getRegistrationCenterApiKey } from '../utils';

export const getFieldsMetadata = (pageLanguage: string, _accessToken: string): Promise<FieldsMetadata> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');

    requestHeaders.append('Authorization', getRegistrationCenterApiKey(settings.backend.hashKey));
    
    if (pageLanguage) {
        requestHeaders.append('Accept-Language', pageLanguage)
    }
    
    return fetchApiWrapper(`${settings.backend.regcenter}/fields-metadata`, {
        headers: requestHeaders,
        method: 'GET',
      });
}