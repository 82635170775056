import { UserApiModel } from "../../api/userProfileDataApi";

export interface Profile extends UserApiModel {
    sub?: string;
    given_name?: string;
    family_name?: string;
    nickname?: string;
    name?: string;
    picture?: string;
    updated_at?: string;
    email_verified?: boolean;
    connections?: [];
    identities?: Identity[]
}

export interface Identity {
    connection: string;
    metadata: MetaData;
    provider: string;
    user_id: string;
}

interface MetaData {
    LockedProfileFields: string
}

export enum ProfileActionTypes {
    LOGIN = '@@profile/LOGIN',
    LOGOUT = '@@profile/LOGOUT',
    GET_USER_ERROR = '@@profile/GET_USER_ERROR',
    USER_REQUEST = '@@profile/USER_REQUEST',
    FETCH_LOGIN = '@@profile/FETCH_LOGIN',
    CHECK_USER = '@@profile/CHECK_USER',
    AUTHENTICATION_USER = '@@profile/AUTHENTICATION_USER',

    UPDATE_USER = '@@profile/UPDATE_USER',
    UPDATE_USER_SUCCEED = '@@profile/UPDATE_USER_SUCCEED',
    UPDATE_USER_FAILED = '@@profile/UPDATE_USER_FAILED',

    FETCH_USER = '@@profile/FETCH_USER',
    FETCH_USER_SUCCEED = '@@profile/FETCH_USER_SUCCEED',
    FETCH_USER_FAILED = '@@profile/FETCH_USER_FAILED',

    PROGRESSIVE_PROFILING_SUCCEED = "@@profile/PROGRESSIVE_PROFILING_SUCCEED"
}
