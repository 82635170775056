import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import styles from './Header.module.scss';
import { transparent } from 'material-ui/styles/colors';
import { DanfossLogo } from '@danfoss/webex-ui/dist/mui';
import settings from '../../config/settings';

const useStyles = makeStyles(theme => ({
	filler: {
		flexGrow: 1,
	},
	appBar: {
		backgroundColor: transparent,
		[theme.breakpoints.down('md')]: {
			position: 'static',
		},
	},
	toolbar: {
		flexWrap: 'wrap',
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		backgroundColor: transparent,
	},
	select: {
		backgroundColor: '#f4f4f4',
		'&:focus': {
			backgroundColor: '#f4f4f4',
		},
	},
}));

const Header: React.FC = () => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar position="static" elevation={0} className={classes.appBar}>
				<Toolbar className={classes.toolbar} disableGutters={true}>
					<DanfossLogo
						pathToImage={`${settings.staticFiles.endpoint}/images/logo_payoff.svg`}
						linkUrlPath={settings.ui.logoUrl}
						className={styles.logo}
					/>
					<div className={classes.filler} />
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
};

export default Header;
