import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import configureStore from './configureStore';
import Main from './containers/Main/Main';

const store = configureStore();

/* istanbul ignore next */
if (process.env.NODE_ENV === 'test') {
	const newNode = document.createElement('div');
	newNode.className = 'registration-center-container';
	document.body.appendChild(newNode);
}

const root = createRoot(document.querySelector(
	'.registration-center-container'
) as HTMLElement);
root.render(<Main store={store} />);
