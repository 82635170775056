import { ProggresiveFieldsActionTypes } from './types';
import { action } from 'typesafe-actions';
import { FieldMetadata } from '../fieldsMetadata/types';

export interface ProgressiveFieldsUpdated {
	type: ProggresiveFieldsActionTypes.UPDATED;
}

export interface ProgressiveFieldsUpdate {
	type: ProggresiveFieldsActionTypes.UPDATE;
	payload: FieldMetadata[];
}

export const ProgressiveFieldsUpdate = (
	fields: FieldMetadata[]
): ProgressiveFieldsUpdate =>
	action(ProggresiveFieldsActionTypes.UPDATE, fields);

export const ProgressiveFieldsUpdated = (): ProgressiveFieldsUpdated =>
	action(ProggresiveFieldsActionTypes.UPDATED);

export type ProgressiveFieldsActionType =
	| ProgressiveFieldsUpdated
	| ProgressiveFieldsUpdate;
