import { fork, all, put } from 'redux-saga/effects';
import cultureSagas from './culture/sagas';
import profileSagas from './profile/sagas';
import applicationSagas from './application/sagas';
import tokenSagas from './token/sagas';
import locationSagas from './location/sagas';
import progressiveFieldsSagas from './progressiveFields/sagas';
import legalInfoSagas from './legalInfo/sagas';
import referencesSagas from './references/sagas';
import fieldsMetadataSagas from './fieldsMetadata/sagas';
import { GetCultures } from './culture/actions';

export default function* rootSaga() {
	yield all([
		fork(cultureSagas),
		fork(profileSagas),
		fork(applicationSagas),
		fork(legalInfoSagas),
		fork(tokenSagas),
		fork(locationSagas),
		fork(progressiveFieldsSagas),
		fork(referencesSagas),
		fork(fieldsMetadataSagas),
		put(GetCultures())
	]);
}
