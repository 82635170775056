import { action } from 'typesafe-actions';
import { LocationActionTypes } from './types';
import {
	LocationApiCountry,
	LocationApiCountryState,
	LocationApiCallingCode,
	Timezone,
} from '../../api/locationApi';

export interface SET_COUNTRIES_NEEDED {
	type: LocationActionTypes.SET_COUNTRIES_NEEDED;
	payload: boolean;
}

export interface GET_COUNTRIES {
	type: LocationActionTypes.GET_COUNTRIES;
}

export interface GET_COUNTRIES_SUCCEED {
	type: LocationActionTypes.GET_COUNTRIES_SUCCEED;
	payload: LocationApiCountry[];
}

export interface SET_TIMEZONES_NEEDED {
	type: LocationActionTypes.SET_TIMEZONES_NEEDED;
	payload: boolean;
}

export interface GET_TIMEZONES {
	type: LocationActionTypes.GET_TIMEZONES;
}

export interface GET_TIMEZONES_SUCCEED {
	type: LocationActionTypes.GET_TIMEZONES_SUCCEED;
	payload: Timezone[];
}

export interface GET_COUNTRIES_TRANSLATION {
	type: LocationActionTypes.GET_COUNTRIES_TRANSLATIONS;
	payload: string;
}

export interface GET_COUNTRIES_TRANSLATION_SUCCEDED {
	type: LocationActionTypes.GET_COUNTRIES_TRANSLATIONS_SUCCEED;
	payload: {
		translations: LocationApiCountry[];
		languageCode: string;
	};
}

export interface SET_STATES_NEEDED {
	type: LocationActionTypes.SET_STATES_NEEDED;
	payload: boolean;
}

export interface GET_STATES {
	type: LocationActionTypes.GET_STATES;
	payload: string;
}

export interface GET_STATES_SUCCEED {
	type: LocationActionTypes.GET_STATES_SUCCEED;
	payload: {
		countryStates: LocationApiCountryState[];
		countryCode: string;
	};
}

export const SetCountriesNeeded = (
	countiresNeeded: boolean
): SET_COUNTRIES_NEEDED =>
	action(LocationActionTypes.SET_COUNTRIES_NEEDED, countiresNeeded);

export const SetStatesNeeded = (statesNeeded: boolean): SET_STATES_NEEDED =>
	action(LocationActionTypes.SET_STATES_NEEDED, statesNeeded);

export const GetCountries = (): GET_COUNTRIES =>
	action(LocationActionTypes.GET_COUNTRIES);

export const SetCountries = (
	countries: LocationApiCountry[]
): GET_COUNTRIES_SUCCEED =>
	action(LocationActionTypes.GET_COUNTRIES_SUCCEED, countries);

export const SetTimezonesNeeded = (timezonesNeeded: boolean): SET_TIMEZONES_NEEDED =>
		action(LocationActionTypes.SET_TIMEZONES_NEEDED, timezonesNeeded);

export const GetTimezones = (): GET_TIMEZONES =>
	action(LocationActionTypes.GET_TIMEZONES);

export const SetTimezones = (timezones: Timezone[]): GET_TIMEZONES_SUCCEED =>
	action(LocationActionTypes.GET_TIMEZONES_SUCCEED, timezones);

export const GetCountriesTranslation = (
	languageCode: string
): GET_COUNTRIES_TRANSLATION =>
	action(LocationActionTypes.GET_COUNTRIES_TRANSLATIONS, languageCode);

export const SetCountriesTranslation = (
	languageCode: string,
	translations: LocationApiCountry[]
): GET_COUNTRIES_TRANSLATION_SUCCEDED =>
	action(LocationActionTypes.GET_COUNTRIES_TRANSLATIONS_SUCCEED, {
		languageCode,
		translations,
	});

export const GetStates = (countryCode: string): GET_STATES =>
	action(LocationActionTypes.GET_STATES, countryCode);

export const SetStates = (
	countryCode: string,
	countryStates: LocationApiCountryState[]
): GET_STATES_SUCCEED =>
	action(LocationActionTypes.GET_STATES_SUCCEED, {
		countryCode,
		countryStates,
	});

export interface SET_CALLING_CODES_NEEDED {
	type: LocationActionTypes.SET_CALLING_CODES_NEEDED;
	payload: boolean;
}

export interface GET_CALLING_CODES {
	type: LocationActionTypes.GET_CALLING_CODES;
	payload: string;
}

export interface GET_CALLING_CODES_SUCCEED {
	type: LocationActionTypes.GET_CALLING_CODES_SUCCEED;
	payload: {
		languageCode: string;
		callingCodes: LocationApiCallingCode[];
	};
}

export const SetCallingCodes = (
	languageCode: string,
	callingCodes: LocationApiCallingCode[]
): GET_CALLING_CODES_SUCCEED =>
	action(LocationActionTypes.GET_CALLING_CODES_SUCCEED, {languageCode, callingCodes});

export const SetCallingCodesNeeded = (
	callingCodes: boolean
): SET_CALLING_CODES_NEEDED =>
	action(LocationActionTypes.SET_CALLING_CODES_NEEDED, callingCodes);

export type LocationActionType =
	| SET_COUNTRIES_NEEDED
	| SET_STATES_NEEDED
	| GET_COUNTRIES_SUCCEED
	| GET_STATES_SUCCEED
	| GET_COUNTRIES_TRANSLATION_SUCCEDED
	| GET_CALLING_CODES_SUCCEED
	| GET_CALLING_CODES
	| SET_CALLING_CODES_NEEDED
	| GET_TIMEZONES_SUCCEED
	| GET_TIMEZONES
	| SET_TIMEZONES_NEEDED;
