import React from 'react';
import { FieldUi } from '../../FieldUi';
import { FormControl, Grid, InputLabel } from '@material-ui/core';
import { getTranslationUi } from '../../../TranslationUi';
import { FieldTooltip } from '../FieldTooltip/FieldTooltip';
import { makeStyles } from '@material-ui/styles';
import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';

export interface LabeledFieldUi extends FieldUi {
	textValue: string;
}

const useStyles = makeStyles(() => ({
	text: {
		color: `${colors.webexuiGrayBlueLight}`,
		fontSize: '16px',
	},
}));

const RenderLabelField: React.FC<LabeledFieldUi> = (props, loading) => {
	const classes = useStyles();
	return (
		<Grid className="MuiFormItem">
			<FormControl fullWidth disabled={loading}>
				<Grid className={'MuiAlignMessage'}>
					<InputLabel shrink htmlFor="" disabled={loading}>
						{getTranslationUi(
							`user_profile.${props.name}.label`,
							props.label.defaultText
						)}{' '}
						<FieldTooltip fieldName={props.name} />
					</InputLabel>
				</Grid>
				<Grid item className={classes.text}>
					{props.textValue || props.value}
				</Grid>
			</FormControl>
		</Grid>
	);
};

export default RenderLabelField;
