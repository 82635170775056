import React from 'react';
import { FieldUi } from '../../FieldUi';
import { Field, WrappedFieldProps } from 'redux-form';
import { FormControlLabel, Grid } from '@material-ui/core';
import { DanfossCheckbox } from '@danfoss/webex-ui/dist/mui';
import settings from '../../../../../config/settings';

type CheckboxFieldProps = {
	label: any;
	disabled: boolean;
	errorText?: string
	onChange?: Function
} & WrappedFieldProps;

const renderCheckboxFieldControl = ({
	input,
	label,
	disabled,
	errorText,
	onChange
}: CheckboxFieldProps) => {
	return (
		<Grid item className="MuiFormItem" xs={12}>
			{errorText && (
				<Grid item className="MuiFormCheckboxError">{errorText}</Grid>
			)}
			<FormControlLabel
				control={
					<div className="MuiFormCheckbox">
						<DanfossCheckbox
							pathToImagesFolder={`${settings.staticFiles.endpoint}/images`}
							{...input}
							{...{ disabled }}
							{...onChange}							
						/>
					</div>
				}
				label={label}
			/>
		</Grid>
	);
};

const handleOnChange = (e: any, setErrorText: Function, linkClicked: boolean, requireLinkClick: boolean) => {
	if (!linkClicked && requireLinkClick) {
		e.target.checked = false
		e.preventDefault()
		setErrorText();
	}
}

const CheckboxField: React.FC<FieldUi & { loading: boolean, errorText: string, setErrorText: Function, linkClicked: boolean, requireLinkClick: boolean }> = props => {
	return (
		<Field
			name={props.name}
			label={props.label}
			component={renderCheckboxFieldControl}
			disabled={props.loading}
			errorText={props.errorText}
			onChange={(e: any) => handleOnChange(e, props.setErrorText, props.linkClicked, props.requireLinkClick)}
		/>
	);
};

export default CheckboxField;