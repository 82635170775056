import {
	LocationApiCountry,
	LocationApiCountryState,
	LocationApiCallingCode,
	Timezone,
} from '../../api/locationApi';

export enum LocationActionTypes {
	SET_COUNTRIES_NEEDED = '@@location/SET_COUNTRIES_NEEDED',
	GET_COUNTRIES = '@@location/GET_COUNTRIES',
	GET_COUNTRIES_SUCCEED = '@@location/GET_COUNTRIES_SUCCEED',

	GET_COUNTRIES_TRANSLATIONS = '@@location/GET_COUNTRIES_TRANSLATIONS',
	GET_COUNTRIES_TRANSLATIONS_SUCCEED = '@@location/GET_COUNTRIES_TRANSLATIONS_SUCCEED',

	SET_STATES_NEEDED = '@@location/SET_STATES_NEEDED',
	GET_STATES = '@@location/GET_STATES',
	GET_STATES_SUCCEED = '@@location/GET_STATES_SUCCEED',

	SET_CALLING_CODES_NEEDED = '@@location/SET_CALLING_CODES_NEEDED',
	GET_CALLING_CODES = '@@location/GET_CALLING_CODES',
	GET_CALLING_CODES_SUCCEED = '@@location/GET_CALLING_CODES_SUCCEED',

	SET_TIMEZONES_NEEDED = '@@location/SET_TIMEZONES_NEEDED',
	GET_TIMEZONES = '@@location/GET_TIMEZONES',
	GET_TIMEZONES_SUCCEED = '@@location/GET_TIMEZONES_SUCCEED'
}

export interface LocationAppStateModel
	extends CountriesAppStateModel,
		StateAppStateModel,
		CallingCodesAppStateModel,
		TimezonesAppStateModel {}

interface CountriesAppStateModel {
	countries?: LocationApiCountry[];
	countriesTranslations: {
		[key: string]: LocationApiCountryState[];
	};
	countriesNeeded?: boolean;
}

interface StateAppStateModel {
	states?: {
		[key: string]: LocationApiCountryState[];
	};
	statesNeeded?: boolean;
}

interface CallingCodesAppStateModel {
	callingCodes?: {
		[key: string]: LocationApiCallingCode[];
	};
	callingCodesNeeded?: boolean;
}

interface TimezonesAppStateModel {
	timezones?: Timezone[];
	timezonesNeeded?: boolean;
}
