import { ReferencesActionTypes, ReferencesAppStateModel } from './types';
import { createReducer } from 'typesafe-actions';
import { ReferencesActionType } from './actions';

const reducer = createReducer<ReferencesAppStateModel, ReferencesActionType>({}, {
	[ReferencesActionTypes.SET_LANGUAGES_NEEDED]: (state, action) => {
		return { ...state, languagesNeeded: action.payload };
	},
	[ReferencesActionTypes.SET_DEPARTMENTS_NEEDED]:
		(state, action) => {
			return { ...state, departmentsNeeded: action.payload };
		},
	[ReferencesActionTypes.SET_AREAS_OF_INTEREST_NEEDED]:
		(state, action) => {
			return { ...state, areasOfInterestNeeded: action.payload };
		},
	[ReferencesActionTypes.SET_AREA_OF_INTEREST_NEEDED]:
		(state, action) => {
			return { ...state, areaOfInterestNeeded: action.payload };
		},
	[ReferencesActionTypes.SET_COMPANY_TYPES_NEEDED]:
		(state, action) => {
			return { ...state, companyTypesNeeded: action.payload };
		},
	[ReferencesActionTypes.GET_LANGUAGES_SUCCEED]:
		(state, action) => {
			return { ...state, languages: action.payload };
		},
	[ReferencesActionTypes.GET_DEPARTMENTS_SUCCEED]:
		(state, action) => {
			return { ...state, departments: action.payload };
		},
	[ReferencesActionTypes.GET_AREAS_OF_INTEREST_SUCCEED]:
		(state, action) => {
			return { ...state, areasOfInterest: action.payload };
		},
	[ReferencesActionTypes.GET_AREA_OF_INTEREST_SUCCEED]:
		(state, action) => {
			return { ...state, areaOfInterest: action.payload };
		},
	[ReferencesActionTypes.GET_COMPANY_TYPES_SUCCEED]:
		(state, action) => {
			return { ...state, companyTypes: action.payload };
		}
});

export { reducer as ReferencesReducer };
