import React from 'react';
import { connect } from 'react-redux';
import GeneralGroup from './Groups/GeneralGroup';
import { GroupFieldType } from './GroupFieldType';
import { getGroupConfig } from './GroupConfig';
import { FieldUi } from '../FormFields/FieldUi';
import { GroupUi } from './GroupUi';
import { AppState } from '../../../store';

interface GroupRendererProps {
	fields: FieldUi[];
	loading?: boolean;
	fieldsObject: any
}

const GroupRenderer: React.FC<GroupRendererProps> = props => {
	const { fields, loading } = props;
	const groupConfig = getGroupConfig(props.fieldsObject);

	return (
		<React.Fragment>
			{groupConfig.map(group => {
				// render only fields that are in group
				const fieldsUi = getGroupRelatedFields(group, fields);
				if (!fieldsUi.length) {
					return null;
				}

				const Tag = GroupMap[group.type || GroupFieldType.General];
				return (
					<Tag
						key={group.name}
						loading={loading}
						{...(group as any)}
						fieldsUi={fieldsUi}
					/>
				);
			})}
		</React.Fragment>
	);
};

const getGroupRelatedFields = (group: GroupUi, appRelatedFields: FieldUi[]) => {
	return appRelatedFields
		.filter(field => group.fields.some(groupField => groupField === field.name))
		.sort((field1, field2) => {
			// sort by order in group config, not by order from api
			return (
				group.fields.indexOf(field1.name) - group.fields.indexOf(field2.name)
			);
		});
};

const GroupMap = {
	[GroupFieldType.General]: GeneralGroup,
};

const mapStateToProps = (state: AppState) => ({
	fieldsObject: state.fieldsMetadata.fieldsObject
});

export default connect(mapStateToProps)(GroupRenderer);
