import { takeLatest, all, fork, put, select, call } from 'redux-saga/effects';
import { TokenActionTypes, Token } from './types';
import { ProcessToken, SetAccessToken } from './actions';
import jwt_decode from 'jwt-decode';
import { UpdateUser, ProgressiveProfilingSucceded } from '../profile/actions';
import { getType } from 'typesafe-actions';
import { getNewAccessToken, TokenApiModel } from '../../api/tokenApi';
import { AppState } from '..';
import { FormLoaded, LoadingErrorAction } from '../loading/actions';

export function* processJWTToken(action: ProcessToken) {
	try {
		const token = action.payload;
		const decoded = jwt_decode(token) as Token;
		if (!decoded) {
			const error = new Error('Token Parse Error');
			yield put(ProcessToken.failure(error));
			yield put(LoadingErrorAction(error));
			return;
		}
		decoded.accessToken = token;
		yield put(ProcessToken.success(decoded));
	} catch (error: any) {
		yield put(LoadingErrorAction({ message: error.message, response: error }));
		yield put(ProcessToken.failure(error));
	}
}

export function* updateJWTTokenAfterUserUpdate() {
	try {
		const token: Token = yield select((state: AppState) => state.token);
		const newTokenApiModel: TokenApiModel = yield call(
			getNewAccessToken,
			token.accessToken,
			token.sub,
			token.clientId,
			token.skip_accept_of_application_terms,
			token.skip_accept_of_dip_terms_and_privacy,
			token.skip_accept_of_pipl_terms
		);
		yield put(SetAccessToken(newTokenApiModel.token));
	
		yield put(ProgressiveProfilingSucceded());
	} catch (error: any) {
		yield put(LoadingErrorAction({ message: error.message, response: error }));
		yield put(FormLoaded());
	}
}

function* watchProcessJWTToken() {
	yield takeLatest(TokenActionTypes.PROCESS_TOKEN, processJWTToken);
}

function* watchUserProfileUpdate() {
	yield takeLatest(getType(UpdateUser.success), updateJWTTokenAfterUserUpdate);
}

function* tokenSagas() {
	yield all([fork(watchProcessJWTToken), fork(watchUserProfileUpdate)]);
}

export default tokenSagas;
