import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	text: {
		color: '#616161'
	},
	spaceBelow: {
		marginBottom: '20px'
	}
}));

interface MessageAreaProps {
	appName: string;
	checkboxesAreToBeShown?: boolean,
	fieldsAreToBeShown?: boolean
	infoTextName?: string
}

const MessageArea: React.FC<MessageAreaProps> = ({ appName, checkboxesAreToBeShown, fieldsAreToBeShown, infoTextName }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Container>
			<CssBaseline />
			{infoTextName ? (
				<Grid className={classes.text}>
					<Typography>
						{parse(t(infoTextName, { application: `<span translate="no">${appName}</span>` }))}
					</Typography>
				</Grid>
			) : (
				<>
					<Grid className={`${classes.text} ${classes.spaceBelow}`}>
						<Typography>
							{parse(t('reg_center.text_info', { application: `<span translate="no">${appName}</span>` }))}
						</Typography>
					</Grid>
					{checkboxesAreToBeShown && fieldsAreToBeShown ? (
						<Grid className={classes.text}>
							<Typography>
								{parse(t('reg_center.text_info_astreiks_and_boxes'))}
							</Typography>
						</Grid>
					) : (
						<Grid className={classes.text}>
							<Typography>
								{checkboxesAreToBeShown ? t('reg_center.text_info_boxes') : parse(t('reg_center.text_info_asteriks'))}
							</Typography>
						</Grid>
					)}
				</>
			)}


		</Container>
	);
};

export default MessageArea;
