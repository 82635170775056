export enum FormFieldType {
	Text = 'text',
	Select = 'select',
	Date = 'date',
	Checkbox = 'checkbox',
	Country = 'country',
	CallingCode = 'callingCode',
	PhoneNumber = 'phonenumber',
	Label = 'label',
}
