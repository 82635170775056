import { takeLatest, all, put, fork, select } from 'redux-saga/effects';
import { AppState } from '../index';
import { Token, TokenActionTypes } from '../token/types';
import { SetFieldsMetadata, SetFieldDataSuccess } from './actions';
import { getFieldsMetadata } from '../../api/fieldsMetadataApi';
import { FieldsMetadata } from './types';
import { LoadingErrorAction } from '../loading/actions';

function* loadFieldMetadata() {
    const currentCulture: string = yield select((state: AppState) => state.cultures.currentCulture);
    const token: Token = yield select((state: AppState) => state.token);

    if (
        !token ||
        !token.accessToken
    ) {
        const error = new Error('Token is not valid to retrieve fieldsMetadata');
        yield put(LoadingErrorAction(error));
        return;
    }

    const fieldsMetadata: FieldsMetadata = yield getFieldsMetadata(currentCulture, token.accessToken);
    yield put(SetFieldsMetadata(fieldsMetadata));
    yield put(SetFieldDataSuccess());
    
}

function* watchLoadFieldMetadata() {
    yield takeLatest(TokenActionTypes.PROCESS_TOKEN_SUCCEEDED, loadFieldMetadata);
}

export default function* fieldsMetadataSagas() {
    yield all([
        fork(watchLoadFieldMetadata)
    ]);
}