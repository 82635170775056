import { action } from 'typesafe-actions';
import { ReferencesActionTypes, Language } from './types';

import { ReferenceItemApiModel } from '../../api/referencesApi';

export interface GET_LANGUAGES {
	type: ReferencesActionTypes.GET_LANGUAGES;
}

export interface GET_LANGUAGES_SUCCEED {
	type: ReferencesActionTypes.GET_LANGUAGES_SUCCEED;
	payload: Language[];
}

export interface SET_LANGUAGES_NEEDED {
	type: ReferencesActionTypes.SET_LANGUAGES_NEEDED;
	payload: boolean;
}

export interface GET_DEPARTMENTS {
	type: ReferencesActionTypes.GET_DEPARTMENTS;
}

export interface GET_DEPARTMENTS_SUCCEED {
	type: ReferencesActionTypes.GET_DEPARTMENTS_SUCCEED;
	payload: ReferenceItemApiModel[];
}

export interface SET_DEPARTMENTS_NEEDED {
	type: ReferencesActionTypes.SET_DEPARTMENTS_NEEDED;
	payload: boolean;
}

export interface GET_COMPANY_TYPES {
	type: ReferencesActionTypes.GET_COMPANY_TYPES;
}

export interface GET_COMPANY_TYPES_SUCCEED {
	type: ReferencesActionTypes.GET_COMPANY_TYPES_SUCCEED;
	payload: ReferenceItemApiModel[];
}

export interface SET_COMPANY_TYPES_NEEDED {
	type: ReferencesActionTypes.SET_COMPANY_TYPES_NEEDED;
	payload: boolean;
}

export interface GET_AREAS_OF_INTEREST {
	type: ReferencesActionTypes.GET_AREAS_OF_INTEREST;
}

export interface GET_AREAS_OF_INTEREST_SUCCEED {
	type: ReferencesActionTypes.GET_AREAS_OF_INTEREST_SUCCEED;
	payload: ReferenceItemApiModel[];
}

export interface GET_AREA_OF_INTEREST {
	type: ReferencesActionTypes.GET_AREA_OF_INTEREST;
}

export interface GET_AREA_OF_INTEREST_SUCCEED {
	type: ReferencesActionTypes.GET_AREA_OF_INTEREST_SUCCEED;
	payload: ReferenceItemApiModel[];
}

export interface SET_AREAS_OF_INTEREST_NEEDED {
	type: ReferencesActionTypes.SET_AREAS_OF_INTEREST_NEEDED;
	payload: boolean;
}

export interface SET_AREA_OF_INTEREST_NEEDED {
	type: ReferencesActionTypes.SET_AREA_OF_INTEREST_NEEDED;
	payload: boolean;
}

export const GetLanguages = (): GET_LANGUAGES =>
	action(ReferencesActionTypes.GET_LANGUAGES);

export const SetLanguages = (languages: Language[]): GET_LANGUAGES_SUCCEED =>
	action(ReferencesActionTypes.GET_LANGUAGES_SUCCEED, languages);

export const SetLanguagesNeeded = (
	languagesNeeded: boolean
): SET_LANGUAGES_NEEDED =>
	action(ReferencesActionTypes.SET_LANGUAGES_NEEDED, languagesNeeded);

export const GetDepartments = (): GET_DEPARTMENTS =>
	action(ReferencesActionTypes.GET_DEPARTMENTS);

export const SetDepartments = (
	departments: ReferenceItemApiModel[]
): GET_DEPARTMENTS_SUCCEED =>
	action(ReferencesActionTypes.GET_DEPARTMENTS_SUCCEED, departments);

export const SetDepartmentsNeeded = (
	departmentsNeeded: boolean
): SET_DEPARTMENTS_NEEDED =>
	action(ReferencesActionTypes.SET_DEPARTMENTS_NEEDED, departmentsNeeded);

export const GetCompanyTypes = (): GET_COMPANY_TYPES =>
	action(ReferencesActionTypes.GET_COMPANY_TYPES);

export const SetCompanyTypes = (
	companyTypes: ReferenceItemApiModel[]
): GET_COMPANY_TYPES_SUCCEED =>
	action(ReferencesActionTypes.GET_COMPANY_TYPES_SUCCEED, companyTypes);

export const SetCompanyTypesNeeded = (
	companyTypesNeeded: boolean
): SET_COMPANY_TYPES_NEEDED =>
	action(ReferencesActionTypes.SET_COMPANY_TYPES_NEEDED, companyTypesNeeded);

export const GetAreasOfInterest = (): GET_AREAS_OF_INTEREST =>
	action(ReferencesActionTypes.GET_AREAS_OF_INTEREST);

export const GetAreaOfInterest = (): GET_AREA_OF_INTEREST =>
	action(ReferencesActionTypes.GET_AREA_OF_INTEREST);

export const SetAreasOfInterest = (
	areasOfInterest: ReferenceItemApiModel[]
): GET_AREAS_OF_INTEREST_SUCCEED =>
	action(ReferencesActionTypes.GET_AREAS_OF_INTEREST_SUCCEED, areasOfInterest);

export const SetAreaOfInterest = (
	areaOfInterest: ReferenceItemApiModel[]
): GET_AREA_OF_INTEREST_SUCCEED =>
	action(ReferencesActionTypes.GET_AREA_OF_INTEREST_SUCCEED, areaOfInterest);

export const SetAreasOfInterestNeeded = (
	areasOfInterestNeeded: boolean
): SET_AREAS_OF_INTEREST_NEEDED =>
	action(
		ReferencesActionTypes.SET_AREAS_OF_INTEREST_NEEDED,
		areasOfInterestNeeded
	);

export const SetAreaOfInterestNeeded = (
	areaOfInterestNeeded: boolean
): SET_AREA_OF_INTEREST_NEEDED =>
	action(
		ReferencesActionTypes.SET_AREA_OF_INTEREST_NEEDED,
		areaOfInterestNeeded
	);

export type ReferencesActionType =
	| GET_LANGUAGES_SUCCEED
	| GET_LANGUAGES
	| SET_LANGUAGES_NEEDED
	| GET_DEPARTMENTS_SUCCEED
	| GET_DEPARTMENTS
	| SET_DEPARTMENTS_NEEDED
	| GET_COMPANY_TYPES_SUCCEED
	| GET_COMPANY_TYPES
	| SET_COMPANY_TYPES_NEEDED
	| GET_AREAS_OF_INTEREST_SUCCEED
	| GET_AREAS_OF_INTEREST
	| SET_AREAS_OF_INTEREST_NEEDED
	| GET_AREA_OF_INTEREST_SUCCEED
	| GET_AREA_OF_INTEREST
	| SET_AREA_OF_INTEREST_NEEDED;
