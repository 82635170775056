import { createAsyncAction } from 'typesafe-actions'
import { ApplicationActionTypes, } from './types';
import { ApplicationApiModel } from '../../api/applicationDataApi';

export interface FetchApplicationData {
	type: ApplicationActionTypes.FETCH_DATA;
}

export interface FetchApplicationDataSucceed {
	type: ApplicationActionTypes.FETCH_DATA_SUCCEED;
	payload: ApplicationApiModel;
}


export interface FetchApplicationDataFailed {
	type: ApplicationActionTypes.FETCH_DATA_FAILED;
	payload: Error;
}
export const FetchData = createAsyncAction(
	ApplicationActionTypes.FETCH_DATA,
	ApplicationActionTypes.FETCH_DATA_SUCCEED,
	ApplicationActionTypes.FETCH_DATA_FAILED
)<string, ApplicationApiModel, Error>();


export type ApplicationActionType = FetchApplicationData | FetchApplicationDataSucceed | FetchApplicationDataFailed;