import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

const renderFromHelper = ({ error, warning }: any) => {
	if (!(error || warning)) {
		return null;
	}

	return <FormHelperText>{error || warning}</FormHelperText>;
};

export default renderFromHelper;
