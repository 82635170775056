import React from 'react';
import { getCookieCategoriesAcceptance } from './CookieInformation';
import settings from '../../config/settings';
import { CookieRemove } from '../../utils/cookies';
import Logger from '../Logger/Logger';

const GoogleAnalytics: React.FC = () => {
	if (!settings.googleTagManager.id || !settings.googleAnalytics.id) {
		const logger = new Logger();
		logger.warn("GTM ID or GA ID hasn't been set");
	} else {
		// When cookieinformation is loaded we need to trigger this function
		window.addEventListener(
			'CookieInformationConsentGiven',
			function (event:any) { 
        
				event = {...event, origin: (event?.target?.location?.origin || '')}
		
				if (event.origin !== settings.application.url) {
					return 
				}

				acceptanceHandler();
				
				},
			false
		);
	}
	return null;
};

const acceptanceHandler = () => {
	// Get user cookie acceptance from cookieinformation
	const acceptance = getCookieCategoriesAcceptance();

	// If the user has accepted statistic cookies we can continue
	if (acceptance && acceptance.statistic) {
		// Enable google analytics
		(window as any)[`ga-disable-${settings.googleAnalytics.id}`] = false;
		const existingHeadElement = document.getElementById(
			'googleTagManager_head'
		);
		if (!existingHeadElement) {
			const googleTagManagerHead = document.createElement('script');

			// Add a generated nonce to the script include for the googleTagManager script when adding inline scripts to our page  
			const googleNonce = document.getElementById('googleNonce') as HTMLMetaElement;
			googleNonce && googleTagManagerHead.setAttribute('nonce', googleNonce.content);

			googleTagManagerHead.id = 'googleTagManager_head';
			googleTagManagerHead.innerText =
				// tslint:disable-next-line:prefer-template
				"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.id='googleTagManager_head_async';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" +
				settings.googleTagManager.id +
				"');";
			document.head.insertBefore(
				googleTagManagerHead,
				document.head.firstChild
			);
		}
		const existingBodyElement = document.getElementById(
			'googleTagManager_body'
		);
		if (!existingBodyElement) {
			const googleTagManagerBody = document.createElement('noscript');
			googleTagManagerBody.id = 'googleTagManager_body';
			const googleTagManagerBodyIframe = document.createElement('iframe');
			googleTagManagerBodyIframe.src = `https://www.googletagmanager.com/ns.html?id=${settings.googleTagManager.id}`;
			googleTagManagerBodyIframe.height = '0';
			googleTagManagerBodyIframe.width = '0';
			googleTagManagerBodyIframe.style.display = 'none';
			googleTagManagerBodyIframe.style.visibility = 'hidden';

			googleTagManagerBody.appendChild(googleTagManagerBodyIframe);
			document.body.insertBefore(
				googleTagManagerBody,
				document.body.firstChild
			);
		}
	} else {
		// Disable google analytics
		(window as any)[`ga-disable-${settings.googleAnalytics.id}`] = true;
		// Delete Google Analytics cookies
		const hostname = (window as any).location.hostname;
		CookieRemove(`_gat_${settings.googleAnalytics.id}`, hostname);
		CookieRemove('_gid', hostname);
		CookieRemove('_ga=', hostname);
	}
};

export default GoogleAnalytics;
