import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";
import i18n from "i18next";

const Title: React.FC = () => {
	const { t } = useTranslation();
	const lang = i18n.language;
	
	return (
		<Helmet 
			defaultTitle={t('login_signup.navigation.login_tab')}
			htmlAttributes={{lang, translate:'no'}}
		/>
	);
};
export default Title;