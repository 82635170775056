import { GroupUi } from './GroupUi';


const getPersonalGroupUI = (fieldsObject: any) => {
	return {
		layout: 'vertical',
		fields: [
			fieldsObject.first_name.name,
			fieldsObject.last_name.name,
			fieldsObject.language.name,
			fieldsObject.company.name
		],
		name: 'personal',
	} as GroupUi;
}

const getPhoneGroupUI = (fieldsObject: any) => {
	return {
		//layout: 'horizontal', //do not fit design
		fields: [fieldsObject.calling_code.name, fieldsObject.phone_number.name],
		name: 'phone',
	} as GroupUi;
}

const getContactGroupUI = (fieldsObject: any) => {
	return {
		fields: [
			fieldsObject.street.name,
			fieldsObject.city.name,
			fieldsObject.zip.name,
			fieldsObject.country.name,
			fieldsObject.state.name,
			fieldsObject.timezone.name,
			fieldsObject.department.name,
			fieldsObject.company_type.name,
			fieldsObject.areas_of_interest.name,
			fieldsObject.area_of_interest.name,
		],
		name: 'contact',
	} as GroupUi;
}

export const getGroupConfig = (fieldsObject: any) => {
 return [getPersonalGroupUI(fieldsObject), getPhoneGroupUI(fieldsObject), getContactGroupUI(fieldsObject)]
}
