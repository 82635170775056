import deepmerge from 'deepmerge';
import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { MaterialTheme } from '@danfoss/webex-ui/dist/mui';

const themeDIP = {
	overrides: {
		MuiContainer: {
			root: {
				[MaterialTheme.breakpoints.only('xs')]: {
					paddingLeft: 0,
					paddingRight: 0,
					overflow: 'hidden'
				}
			}
		},
		MuiFormLabel: {
			root: {
				'&.MuiInputLabel-root': {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					'&>span:first-of-type': {
						order: 1,
					},
				},
				'&.MuiFormInputLanguage': {
					color: 'white',
				},
				'&$focused': {
					'&.MuiFormInputLanguage': {
						color: 'white',
					},
				},
			},
		},
		MuiTooltip: {
			tooltip: {
				'&.MuiTooltip-tooltip': {
					backgroundColor: colors.webexuiWhite,
					color: '#686868',
					border: `1px solid ${colors.webexuiGray}`,
					fontFamily: 'myriad-pro, Myriad Pro',
					fontSize: '16px',
					fontWeight: 'normal',
					lineHeight: '24px',
					padding: '15px',
					boxShadow: '0 1px 4px 0 rgba(0,0,0,0.5)',
					borderRadius: '2px',
					minWidth: '322px',
				},
			},
			popper: {
				top: '16px !important',
			},
		},
		MuiGrid: {
			root: {
				'&.MuiFormItem': {
					marginTop: -5,
				},
				'&.MuiFormCheckboxError': {
					color: colors.webexuiRed,
					fontSize: '16px',
					letterSpacing: 'normal',
					marginBottom: '10px'
				}
			},
			container: {
				flexGrow: 0,
				backgroundColor: 'white',
				paddingTop: MaterialTheme.spacing(4),
				paddingBottom: MaterialTheme.spacing(4),
				[MaterialTheme.breakpoints.up('sm')]: {
					borderRadius: 6,
				},
				[MaterialTheme.breakpoints.only('xs')]: {
					borderRadius: 0,
					marginBottom: 0,
					height: '100%',
					paddingLeft: '16px',
					paddingRight: '16px'
				}
			},
			'spacing-xs-2': {
				'& > $item': {
					padding: '9px 11px'
				}
			}
		},
		MuiFormControl: {
			root: {
				"& div.MuiInputBase-root": {
					height: '36px',
					backgroundColor: '#f4f4f4',
					borderRadius: '2px !important',
					border: 0,
					fontSize: '15px !important',
					fontFamily: 'myriad-pro, Myriad Pro',
					fontWeight: '400',
					lineHeight: '24px',
					
				},
				"& fieldset.MuiOutlinedInput-notchedOutline": {
					border: 0
				},
				"& div.MuiSelect-select": {
					padding: '4px 10px',
				},
				"& input.Mui-disabled": {
					color: "#aaaaaa !important"
				}
			}
		},
		MuiPaper: {
			root: {
				"& li.MuiMenuItem-root": {
					fontSize: '15px !important',
					padding: '2px 10px'
				}
			}
		},
		MuiFormControlLabel: {
			root: {
				alignItems: 'baseline',
			},
			label: {
				lineHeight: '21px',
			},
		},
		MuiInput: {
			underline: {
				"&.select": {
					padding: '5px 0px',
				}
			}
		},
		MuiButton: {
			root: {
				[MaterialTheme.breakpoints.only('xs')]: {
					width: '100%'
				}
			}
		},
		MuiFormHelperText: {
			root: {
				color: colors.webexuiRed,
				fontSize: '16px',
				letterSpacing: 'normal'
			}
		}
	},
};
export default deepmerge(themeDIP, MaterialTheme);
