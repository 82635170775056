import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';

export interface TokenApiModel {
	token: string;
}

export const getNewAccessToken = (
	accessToken: string,
	userId: string,
	clientId: string,
	skip_accept_of_application_terms?: boolean,
	skip_accept_of_dip_terms_and_privacy?: boolean,
	skip_accept_of_pipl_terms?: boolean
): Promise<TokenApiModel> =>
	fetchApiWrapper(`${settings.backend.regcenter}/token?skip_accept_of_application_terms=${skip_accept_of_application_terms || false}&skip_accept_of_dip_terms_and_privacy=${skip_accept_of_dip_terms_and_privacy || false}&skip_accept_of_pipl_terms=${skip_accept_of_pipl_terms || false}`, {
		headers: {
			Authorization: `BEARER ${accessToken}`,
			'Content-Type': 'application/json; charset=utf-8',
		},
		body: JSON.stringify({
			user_id: userId,
			client_id: clientId
		}),
		method: 'POST',
	});
