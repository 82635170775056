/* istanbul ignore file */

interface LogError {
	debug(primaryMessage: string, ...additionalData: any[]): void;
	warn(primaryMessage: string, ...additionalData: any[]): void;
	error(primaryMessage: string, ...additionalData: any[]): void;
	info(primaryMessage: string, ...additionalData: any[]): void;
}

class Logger implements LogError {
	debug(msg: string, ...additionalData: any[]) {
		this.logMessage('debug', msg, additionalData);
	}

	warn(msg: string, ...additionalData: any[]) {
		this.logMessage('warn', msg, additionalData);
	}

	error(msg: string, ...additionalData: any[]) {
		this.logMessage('error', msg, additionalData);
	}

	info(msg: string, ...additionalData: any[]) {
		this.logMessage('info', msg, additionalData);
	}

	logMessage(
		msgType: 'debug' | 'info' | 'warn' | 'error',
		msg: string,
		data: any[]
	) {
		if (process.env.NODE_ENV === 'test') {
			return null;
		}

		if (
			process.env.NODE_ENV === 'development' ||
			msgType === 'warn' ||
			msgType === 'error'
		) {
			if (data) {
				console[msgType](msg, data);
			} else {
				console[msgType](msg);
			}
		}
	}
}

export default Logger;
