import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';
import Logger from '../components/Logger/Logger';
import { Culture } from '../store/culture/types';

export interface CultureApi {
	items: Culture[]
}
export const getCultures = (): Promise<CultureApi> => {
	if (!settings.backend.myaccount) {
		const logger = new Logger();
		logger.error('settings.backend.myaccount should be provided');
		return Promise.resolve({items: []});
	}
	
	return fetchApiWrapper<CultureApi>(
		`${settings.backend.myaccount}/references/languages`,
		{
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			method: 'GET',
		}
	);
};

export const getCookieInformationLangauges = () => {
	
	return fetchApiWrapper<any>(
		`${settings.staticFiles.endpoint}/localization/cookieInformation.json`,
		{
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			method: 'GET',
		}
	);
};
