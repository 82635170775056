import fetchApiWrapper from './fetchApiWrapper';
import settings from '../config/settings';

export interface LocationApiCountry {
    alpha2: string,
    name: string
}

export interface LocationApiCountries {
    value: LocationApiCountry[]
}

export interface LocationApiCountryStates {
    value: LocationApiCountryStateValue[]
}

export interface LocationApiCountryStateValue {
    name: string,
    divisions: LocationApiCountryState[]
}

export interface LocationApiCountryState {
    id: string,
    name: string
}

export interface LocationApiTimezones {
    value: LocationApiTimezone[]
}

export interface LocationApiTimezone {
    id: string,
    name: string,
    base_utc_offset: string
}

export interface Timezone {
    id: string,
    text: string    
}

export interface LocationApiCallingCodes {
    value: LocationApiCallingCodeValue[]
}

export interface LocationApiCallingCodeValue {
    name: string,
    alpha2: string,
    calling_codes: string[]
}

export interface LocationApiCallingCode {
	countryCode: string;
	countryName: string;
	callingCode: string;
}

export const getCountries = async (accessToken: string): Promise<LocationApiCountries> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.myaccount}/location/countries?%24select=alpha2%2C%20name&%24orderby=name`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getCountriesTranslations = (
	accessToken: string,
	pageLanguage: string
): Promise<LocationApiCountries> => {
	const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.myaccount}/location/countries?%24select=alpha2%2C%20name&%24orderby=name&language=${pageLanguage}`, {
        headers: requestHeaders,
        method: 'GET',
      });
};

export const getStates = (accessToken: string, countryCode: string, pageLanguage: string): Promise<LocationApiCountryStates> => {
	const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.myaccount}/location/countries?%24select=name&%24filter=alpha2%20eq%20'${countryCode}'&%24expand=divisions&language=${pageLanguage}`, {
        headers: requestHeaders,
        method: 'GET',
      });
};

export const getCallingCodes = (accessToken: string, pageLanguage: string): Promise<LocationApiCallingCodes> => {
	const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.myaccount}/location/countries?%24select=name%2C%20alpha2%2C%20calling_codes&%24orderby=name&language=${pageLanguage}`, {
        headers: requestHeaders,
        method: 'GET',
      });
};

export const getTimezones = async (accessToken: string): Promise<LocationApiTimezones> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.backend.myaccount}/location/timezones?%24select=id%2C%20name%2C%20base_utc_offset`, {
        headers: requestHeaders,
        method: 'GET',
      });
}
