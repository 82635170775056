import { EventWithDataHandler } from 'redux-form';
import { ChangeEvent } from 'react';

export interface FieldsMetadata {
    fields: FieldMetadata[],
    fieldsObject: any
}

export interface FieldMetadata {
    name: string,
    validation?: {
        max_len?: number,
        regex?: string,
        standard?: string
    },
    idp_data_path: string,
    data_type: string,
    display_name: string | undefined,
    label?: {
		default_text: string,
	},
    defaultValue?: string,
    disabled?: boolean,
    required?: boolean,
    value?: string | boolean;
    api_url?: string;
    is_multi_select?: boolean;
    options?: any;
    excludeFromPayload?: boolean;
    onChange?: EventWithDataHandler<ChangeEvent<any>>;
    display_when_field_has_value?: string,
    is_data_required_if_dependend_field_has_value?: boolean,
    is_data_required?: boolean
}

export enum FieldsMetadataActionType {
    SET_FIELDSMETADATA = '@@fieldsMetadata/SET_FIELDSMETADATA',
    SET_FIELDSMETADATA_SUCCESS = '@@fieldsMetadata/SET_FIELDSMETADATA_SUCCESS'
}
