import { FieldUi } from './FieldUi';
import { FormFieldType } from './FormFieldType';
import SelectField, {
	SelectFieldUi,
} from './Components/SelectField/SelectField';
import { TextField } from './Components/TextField';
import { LabelField } from './Components/LabelField';
import React from 'react';
import { Grid } from '@material-ui/core';
import { GroupUiLayoutType } from '../GroupFields/GroupUi';
import { GridSize } from '@material-ui/core/Grid';
import { LabeledFieldUi } from './Components/LabelField/LabelField';
interface FieldRendererProps {
	fields: FieldUi[];
	layout: GroupUiLayoutType;
	loading?: boolean;
}

const FieldRenderer: React.FC<FieldRendererProps> = props => {
	const { fields, layout, loading } = props;
	
	if (!fields.length) return null;
	const columnsPerField = (layout === 'horizontal'
		? Math.floor(12 / fields.length)
		: 12) as GridSize;

	return (
		<React.Fragment>
			{fields.map(field => {
				return (
					<Grid item xs={columnsPerField} key={field.name}>
						{renderSwitchField(field, loading)}
					</Grid>
				);
			})}
		</React.Fragment>
	);
};

const renderSwitchField = (field: FieldUi, loading: boolean | undefined) => {
	switch (field.data_type) {
		case FormFieldType.Label:
			return LabelField(field as LabeledFieldUi, loading);
		case FormFieldType.Select:
			return SelectField(field as SelectFieldUi, loading);
		case FormFieldType.Text:
		case FormFieldType.Date:
			return TextField(field, loading);
		case FormFieldType.Country:
			return SelectField(field as SelectFieldUi, loading);
		case FormFieldType.CallingCode:
			return SelectField(field as SelectFieldUi, loading);
		case FormFieldType.PhoneNumber:
			return TextField(field, loading);
		default:
			return null;
	}
};

export default FieldRenderer;
