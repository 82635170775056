import { ApplicationActionTypes } from './types';
import { createReducer } from 'typesafe-actions';
import { ApplicationActionType } from './actions';
import { ApplicationApiModel } from '../../api/applicationDataApi';

export const initialState = null;

const reducer = createReducer<ApplicationApiModel, ApplicationActionType>(
	(initialState as unknown) as ApplicationApiModel
, {
	[ApplicationActionTypes.FETCH_DATA_SUCCEED]: (state, action) => {
		return { ...state, ...action.payload };
	},
	[ApplicationActionTypes.FETCH_DATA_FAILED]: (_state, _action) => {
		return (null as unknown) as ApplicationApiModel;
	}
});

export { reducer as applicationReducer };
