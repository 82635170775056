import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    iconStyle: {
        position: 'absolute',
        top: '0',
        right: '0',
        display: 'inline-block',
        width: '13px',
        height: '13px',
        margin: '12px 10px',
        pointerEvents: 'none'
    }
}));