import { CulturesActionType, Cultures } from './types';
import { createReducer } from 'typesafe-actions';
import { CultureActionType } from './actions';

const reducer = createReducer<Cultures, CultureActionType>({
	currentCulture: '',
	cultures: [],
}, {
	[CulturesActionType.SET_CULTURES]: (state: Cultures, action) => {
		return { ...state, cultures: action.payload };
	},
	[
		CulturesActionType.CULTURE_CHANGE]:
		(state: Cultures, action) => {
			return { ...state, currentCulture: action.payload };
		}
});

export { reducer as culturesReducer };
