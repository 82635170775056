import { LegalInfoActionTypes } from './types';
import { createReducer } from 'typesafe-actions';
import { LegalInfoActionType } from './actions';
import { DipLegalInfo } from '../../api/applicationDataApi';

export const initialState = null;

const reducer = createReducer<DipLegalInfo, LegalInfoActionType>(
	(initialState as unknown) as DipLegalInfo
, {
	[
		LegalInfoActionTypes.FETCH_LEGAL_INFO_SUCCEED
	]:
		(state, action) => {
			return { ...state, ...action.payload };
		},
	[
		LegalInfoActionTypes.FETCH_LEGAL_INFO_FAILED
	]:
		(state, action) => {
			return (null as unknown) as DipLegalInfo;
		}
});

export { reducer as legalInfoReducer };
