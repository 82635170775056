import { combineReducers } from 'redux';
import { tokensReducer } from './token/reducers';
import { loadingReducer } from './loading/reducers';
import { profileReducer } from './profile/reducers';
import { culturesReducer } from './culture/reducers';
import { applicationReducer } from './application/reducers';
import { locationReducer } from './location/reducers';
import { fieldsMetadataReducer } from './fieldsMetadata/reducers';
import { reducer as formReducer, FormStateMap } from 'redux-form';
import { Token } from './token/types';
import { Loading } from './loading/types';
import { Profile } from './profile/types';
import { Cultures } from './culture/types';
import { ApplicationApiModel, DipLegalInfo } from '../api/applicationDataApi';
import { LocationAppStateModel } from './location/types';
import { progressiveFieldsReducer } from './progressiveFields/reducers';
import { ProggresiveFields } from './progressiveFields/types';
import { legalInfoReducer } from './legalInfo/reducers';
import { ReferencesAppStateModel } from './references/types';
import { ReferencesReducer } from './references/reducers';
import { FieldsMetadata } from './fieldsMetadata/types';


// Top-level state object
export interface AppState {
	form?: FormStateMap;
	loading?: Loading;
	profile?: Profile;
	token?: Token;
	application: ApplicationApiModel;
	cultures: Cultures;
	location: LocationAppStateModel;
	progressiveFields: ProggresiveFields;
	legalInfo?: DipLegalInfo;
	references: ReferencesAppStateModel;
	fieldsMetadata: FieldsMetadata
}

export const createRootReducer = () =>
	combineReducers({
		form: formReducer,
		profile: profileReducer,
		loading: loadingReducer,
		token: tokensReducer,
		application: applicationReducer,
		cultures: culturesReducer,
		location: locationReducer,
		progressiveFields: progressiveFieldsReducer,
		legalInfo: legalInfoReducer,
		references: ReferencesReducer,
		fieldsMetadata: fieldsMetadataReducer
	});
