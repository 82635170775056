import React, { ReactNode } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { FormControl, InputLabel, Grid } from '@material-ui/core';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import renderFromHelper from '../renderFromHelper';
import { FieldUi } from '../../FieldUi';
import { TranslationUi, getTranslationUi } from '../../../TranslationUi';
import '../../../../../styles/danfossDip/style.scss';
import { FieldTooltip } from '../FieldTooltip/FieldTooltip';
import { urlJoin } from 'url-join-ts';
import settings from '../../../../../config/settings';
import { useStyles } from './Styles';

export const ArrowDownIcon = (disabled: boolean) => {
	const classes = useStyles();
	return <img src={urlJoin(settings.staticFiles.endpoint,  (disabled ? '/images/system/lock.svg' : '/images/system/ChevronDown.svg'))} className={classes.iconStyle} alt="" />
};

export const ArrowDownIconDisabled = () => {
	return ArrowDownIcon(true)
};

export const ArrowDownActive = () => {
	return ArrowDownIcon(false)
};

export interface SelectFieldOption {
	label: TranslationUi;
	value: string;
	selected?: boolean;
}

export interface SelectFieldUi extends FieldUi {
	options: SelectFieldOption[];
	value?: string;
	is_multi_select?: boolean;
}

type SelectFieldProps = {
	name: string;
	children: ReactNode;
	label: string;
	isSelectDisabled: boolean;
	disabled: boolean;
	required: boolean;
} & WrappedFieldProps;

export const RenderSelectFieldControl = ({
	input,
	label,
	meta: { touched, error },
	children,
	name,
	isSelectDisabled,
	disabled,
	required,
	...custom
}: SelectFieldProps) => {
	return (
		<Grid className="MuiFormItem">
			<FormControl
				error={touched && error}
				fullWidth
				required={required}
				disabled={disabled}
			>
				<InputLabel
					disabled={isSelectDisabled || disabled}
					variant="standard"
					shrink
					htmlFor={name}
					id={`${name}-label`}
				>
					{label} <FieldTooltip fieldName={name || input.name} />
				</InputLabel>
				<Select
					{...input}
					{...custom}
					id={name}
					name={name}
					value={typeof(input.value) === 'string' ? [input.value] : input.value}
					inputProps={{
						name,
						id: name,
              			translate: 'no'
					}}
					onChange={(event: any) => input.onChange(event, event.target)}
					IconComponent={() => disabled ? ArrowDownIconDisabled() : ArrowDownActive()}
					disabled={isSelectDisabled || disabled}
					fullWidth
				>
					{children}
					
				</Select>
				{renderFromHelper({ touched, error })}
			</FormControl>
		</Grid>
	);
};

const RenderSelectField: React.FC<SelectFieldUi> = (props, loading) => {

	return (
		<Field
			name={props.name}
			label={getTranslationUi(
				`user_profile.${props.name}.label`,
				props.label.defaultText
			)}
			value={props.value}
			component={RenderSelectFieldControl}
			disabled={!props.options || props.options.length === 0 || loading || props.disabled}
			onChange={props.onChange}
			isSelectDisabled={!props.options || props.options.length === 0}
			required={props.required}
			multiple={props.is_multi_select ?? false}
		>	
			{!props.is_multi_select && 
				<MenuItem key="unselected" value="" disabled={props.required}>&nbsp;</MenuItem>
			}
			{props.options &&
				props.options.map(option => {
					const translation = getTranslationUi(
						option.label.translationKey ||
							`references.${props.name}.${option.value}`,
						option.label?.defaultText || option.value
					);

					return (
						<MenuItem key={`${option.value}`} value={option.value}>
							{translation}
						</MenuItem>
					);
				})}
		</Field>
	);
};

export default RenderSelectField;
