import { ProfileActionTypes, Profile } from './types';
import { createReducer } from 'typesafe-actions';
import { UserActionType } from './actions';
import Logger from '../../components/Logger/Logger';

export const initialState = null;

const reducer = createReducer<Profile, UserActionType>(
	(initialState as unknown) as Profile
, {
	[ProfileActionTypes.UPDATE_USER_FAILED]: (state, action) => {
		const ErrorLogger = new Logger();
		ErrorLogger.error(action.payload.message);
		return state;
	},
	[ProfileActionTypes.FETCH_USER_SUCCEED]: (state, action) => {
		return { ...state, ...action.payload };
	},
	[ProfileActionTypes.FETCH_USER_FAILED]: (state, action) => {
		const ErrorLogger = new Logger();
		ErrorLogger.error(action.payload.message);
		return (null as unknown) as Profile;
	}
});

export { reducer as profileReducer };
